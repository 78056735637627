.navContainer{
  position: relative;
  z-index: 999;
  height: 60px;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .info {
    margin-top: 82px;
    height: 90px;
    background: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    .info-center{
      display: inline-flex;
      margin: 0 0 0 50%;
      transform: translateX(-50%);
      .news-container{
        display: flex;
        .news-card{
          width: 263px;
          height: 89px;
          padding: 19px 18px 18px;
          .news-title{
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 17px;
          }
          .news-content{
            margin-top: 6px;
            width: 227px;
            height: 30px;
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 15px;
          }
        }
        .news-line{
          width: 1px;
          height: 47px;
          background: #FFFFFF;
          opacity: 0.3;
          margin: 21px 0;
        }
      }
    }
  }
  .nav-div{
    width: 1440px;
    height: 60px;
    margin: 0 auto;
    background: #FFFFFF;  
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    .nav-icon{
      display: inline-block;
      margin-left: 195px;
      margin-bottom: -10px;
      line-height: 78px;
      width: 80px;
      height: 30px;
      overflow: hidden;
      background: url('../../../官网切图/首页/深色底LOGO.png');
      background-size: 80px 30px;
      background-repeat: no-repeat;
    }
    .name{
      display: inline-block;
      line-height: 78px;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
    }
    .nav-icon-font{
      display: inline-block;
      margin-left: 12px;
      line-height: 60px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #282828;
    }
    .nav-font{
      &:hover{
        color: #EB3A32;
        // .border-line{
        //   display: block;
        // }
      }
      display: inline-block;
      margin-left: 167px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #282828;
      line-height: 60px;
      cursor: pointer;
      // .border-line{
      //   display: none;
      //   margin-top: -24px;
      //   width: 36px;
      //   height: 4px;
      //   background-color: #ffffff;
      //   border-radius: 4px;
      // }
    }
    .nav-fonts{
      margin-left: 44px;
      display: inline-block;
      line-height: 60px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #282828;
      cursor: pointer;
      &:hover{
        color: #EB3A32;
        // .border-line{
        //   display: block;
        // }
      }
      // .border-line{
      //   display: none;
      //   margin-top: -24px;
      //   width: 72px;
      //   height: 4px;
      //   background-color: #ffffff;
      //   border-radius: 4px;
      // }
    }
    .nav-button{
      margin-left: 44px;
      display: inline-block;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #EB3A32;
      border: 1px solid #EB3A32;
    }
    .hover-box{
      &:hover{
        .nav-title{
          display: block;
        }
      }
      .nav-fonts-hover{
        &:hover{
          color: #EB3A32;
          // .border-line{
          //   display: block;
          // }
        }
        margin-left: 44px;
        display: inline-block;
        line-height: 60px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #282828;
        cursor: pointer;
        // .border-line{
        //   display: none;
        //   margin-top: -24px;
        //   width: 132px;
        //   height: 4px;
        //   background-color: #ffffff;
        //   border-radius: 4px;
        // }
      }
    }
    .nav-title{
      display: none;
      position: absolute;
      // left: 764px;
      top: 60px;
      width: 576px;
      height: 238.5px;
      background: #FFFFFF;
      z-index: 2;
      .title-left{
        float: left;
        margin-left: 24px;
        .title{
          margin-top: 20px;
          font-size: 15px;
          font-family: AppleSystemUIFont;
          font-weight: 400;
          color: #595959;
          line-height: 21px;
          .borderLine{
            margin-top: -10px;
            width: 60px;
            height: 6px;
            background: rgba(235, 58, 50, 0.2);
          }
        }
        .content{
          display: block;
          &:hover{
            color: #EB3A32;
          }
          cursor: pointer;
          margin-top: 30px;
          font-size: 18px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #232323;
          line-height: 27px;
        }
      }
      .line{
        float: left;
        margin-top: 19.5px;
        margin-left: 54px;
        width: 2px;
        height: 186px;
        background: linear-gradient(180deg, #FFFFFF 0%, #EB3A32 54%, #FFFFFF 100%);
      }
      .title-right{
        float: left;
        .title{
          margin-left: 30px;
          margin-top: 20px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #595959;
          line-height: 21px;
          .borderLine{
            margin-top: -10px;
            width: 96px;
            height: 6px;
            background: rgba(235, 58, 50, 0.2);
          }
        }
        .content{
          margin-left: 30px;
          .left{
            float: left;
            .fonts{
              display: block;
              text-align: center;
              margin-top: 18px;
              line-height: 23px;
              font-size: 17px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #616161;
            }
          }
          .center{
            float: left;
            margin-left: 58px;
            .fonts{
              display: block;
              text-align: center;
              margin-top: 18px;
              line-height: 23px;
              font-size: 17px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #616161;
            }
          }
          .right{
            float: left;
            margin-left: 41px;
            .fonts{
              display: block;
              text-align: center;
              margin-top: 18px;
              line-height: 23px;
              font-size: 17px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #616161;
            }
          }
        }
      }
    }
    }

    .top-title{
      margin-left: 195px;
      margin-top: 82.5px;
      font-size: 48px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 72px;
    }
    .top-content-top{
      margin-left: 195px;
      margin-top: 18px;
      // width: 417px;
      font-size: 30px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 44px;
    }
    .top-content-center{
      margin-left: 195px;
      margin-top: 18px;
      // width: 336px;
      height: 19px;
      font-size: 19px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 19px
    }
    .top-content-bottom{
      margin-left: 195px;
      margin-top: 30px;
      width: 210px;
      height: 66px;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0px 2px 4px 0px #315ECE;
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      // filter: blur(9px);
      text-align: center;
      line-height: 66px;
      font-size: 36px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
      a{
        color: #FFFFFF;
      }
    }
    .pic-left{
      position: absolute;
      width: 282px;
      height: 96px;
      background: url('../../../官网切图/首页/banner山.png');
      background-size: 100% 100%;
      left: 40px;
      bottom: 48px;
    }
    .pic-right{
      position: absolute;
      width: 460px;
      height: 408px;
      background: url('../../../官网切图/首页/banner 主图.png');
      background-size: 100% 100%;
      top: 98px;
      bottom: 94px;
      right: 130px;
    }
  .modalReport{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      width: 600px;
      height: 500px;
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #ffffff;
      }
  } 
  .ant-modal-header{
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
    border-radius: 8px;
  }
  .ant-modal-title {
    font-size: 27px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 30px;
  }
  .ant-modal-body{
    // padding-top: 12px;
    .title{
      text-align: center;
      font-size: 27px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      margin-bottom: 18px;
      // line-height: 40.5px;
    }
    .icon{
      text-align: center;
      margin: 0 auto;
      width: 300px;
      height: 330px;
      background: #FFFFFF;
      border-radius: 16px;
      border: 2px solid #FFFFFF;
      padding-top: 30px;
      .icon-pic{
        margin: 0 auto;
        width: 240px;
        height: 240px;
        background-image: url('../../../官网切图/二维码/风控报告.png');
        background-size: 100% 100%;
      }
      .icon-title{
        margin-top: 10px;
        font-size: 21px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #282828;
        line-height: 31.5px;
      }
    }
    .icon-tip{
      margin-top: 14px;
      font-size: 21px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 31.5px;
      text-align: center;
    }
  }
  }
  
  .modalRevice{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      margin: 0 auto;
      width: 930px;
      height: 446px;
      box-shadow: 0px 0px 3px 3px rgba(59, 111, 239, 0.1);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 6px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #ffffff;
      }
      .background{
        .title{
          font-size: 27px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 40.5px;
          text-align: center;
        }
        .icons{
          margin: 0 auto;
          width: 876px;
          height: 270px;
          margin-left: 26px;
          margin-top: 24px;
          .icon-left{
            float: left;
            width: 240px;
            height: 300px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .left-pic{
              width: 192px;
              height: 192px;
              margin-top: 18px;
              margin-left: 24px;
              background-image: url("../../../官网切图/首页/二维码.png");
              background-size: 100% 100%;
            }
            .left-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #282828;
              line-height: 30px;
            }
          }
          .icon-center{
            margin-left: 54px;
            float: left;
            width: 240px;
            height: 300px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .center-pic{
              width: 192px;
              height: 192px;
              margin-top: 18px;
              margin-left: 24px;
              background-image: url("../../../官网切图/二维码/安卓.png");
              background-size: 100% 100%;
            }
            .center-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #282828;
              line-height: 30px;
            }
          }
          .icon-right{
            margin-left: 54px;
            float: left;
            width: 240px;
            height: 300px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .right-pic{
              width: 192px;
              height: 192px;
              margin-top: 18px;
              margin-left: 24px;
              background-image: url("../../../官网切图/二维码/IOS.png");
              background-size: 100% 100%;
            }
            .right-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #282828;
              line-height: 30px;
              .tip-small{
                margin-top: -10px;
                font-size: 10px;
              }
            }
          }
        }
      }
    } 
  }
  .modalLease{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      margin: 0 auto;
      width: 660px;
      height: 770px;
      box-shadow: 0px 0px 4px 4px rgba(57, 59, 65, 0.1);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #ffffff;
      }
      .modalContainer{
        .title{
          font-size: 24px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 36px;
          text-align: center;
        }
        .title-top{
          margin-left: 18px;
          margin-top: 30px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 27px;
        }
        .icons{
          margin-left: 18px;
          .iconTop{
            height: 90px;
            margin-top: 12px;
            .left{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              border: 2px solid #ffffff;
              cursor: pointer;
              .left-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_bijiben @2x.png");
                background-size: 100% 100%;
              }
              .left-font{
                float: left;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 90px;
              }
            }
            .center{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              border: 2px solid #ffffff;
              margin-left: 18px;
              .center-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                background-image: url("../../../官网切图/企业租赁/img_taishiji @2x.png");
                background-size: 100% 100%;
              }
              .center-font{
                float: left;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 90px;
              }
            }
            .right{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #ffffff;
              .right-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                background-image: url("../../../官网切图/企业租赁/img_dayinji @2x.png");
                background-size: 100% 100%;
              }
              .right-font{
                float: left;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 90px;
              }
            }
          }
          .iconBottom{
            height: 90px;
            margin-top: 18px;
            .left{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              border: 2px solid #ffffff;
              .left-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                background-image: url("../../../官网切图/企业租赁/img_shouji @2x.png");
                background-size: 100% 100%;
              }
              .left-font{
                float: left;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 90px;
              }
            }
            .center{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #ffffff;
              .center-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                background-image: url("../../../官网切图/企业租赁/img_touyingyi @2x.png");
                background-size: 100% 100%;
              }
              .center-font{
                float: left;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 90px;
              }
            }
            .right{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #ffffff;

              .right-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                background-image: url("../../../官网切图/企业租赁/img_其他服务@2x.png");
                background-size: 100% 100%;
              }
              .right-font{
                float: left;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 90px;
              }
            }
          }
        }
        .title-center{
          margin-left: 18px;
          margin-top: 30px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 27px;
        }
        .textArea{
          margin-left: 18px;
          margin-top: 12px;
          width: 576px;
          height: 198px;
          background: rgba(216, 216, 216, 0.55);
          border-radius: 8px;
          border: 2px solid #FFFFFF;
          overflow: hidden;
          .TextArea{
            .ant-input{
              min-height: 170px;
              resize: none;
              &::-webkit-input-placeholder{
                font-size: 18px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 27px;
                letter-spacing: 2px;
              }
            }
          }
        }
        .form{
          margin-left: 18px;
          margin-top: 30px;
          .title-bottom{
            float: left;
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 36px;
          }
          .input{
            float: left;
            width: 300px;
            height: 36px;
            background: rgba(216, 216, 216, 0.55);
            border-radius: 8px;
            border: 2px solid #FFFFFF;
            overflow: hidden;
            .input-child{
              border: 0px;
              outline: none;
              width: 100%;
              height: 100%;
              background: rgba(216, 216, 216, 0.55);
              &::-webkit-input-placeholder{
                font-size: 16.5px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 33px;
              }
            }
          }
        }
        .button{
          float: left;
          margin-top: 6px;
          margin-left: 150px;
          width: 330px;
          height: 60px;
          text-align: center;
          background: #EB3A32;
          border-radius: 48px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 50px;
        }
      }
    }
  }
}
  



@primary-color: #0099ff;@font-size-base: 14px;