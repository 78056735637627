.news {
    min-width: 1440px;
    background: #F6F6F6;
    .newsBanner {
        // width: 1440px;
        // height: 525px;
        height: 50vh;
        max-height: 525px;
        margin: 0 auto;
        background: url('../../../官网切图/新闻中心/banner.png');
        background-size: 100% 100%;
        overflow: hidden;
        .place {
            height: calc(25vh - 34px);
            max-height: 212px;
        }
        .title {
            // margin-top: 212px;
            height: 68px;
            // margin-top: 25vh;
            text-align: center;
            // transform: translate(0, -50%);
            font-size: 48px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 68px;
        }
        .tip {
            text-align: center;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #FFFFFF;
            line-height: 17px;
            letter-spacing: 8px;
        }
    }
    .newsTabs {
        margin: 105px auto 32px;
        .newsTabsPane {
            width: 1050px;
            // height: 245px;
            margin: 0 auto;
        }
    }
    .iframeContent {
        position: relative;
        margin: 91px auto 46px;
        iframe {
            height: 1080px;
            width: 100%;
        }
        .iframeContentBtn{
            position: absolute;
            left: 5px;
            top: 5px;
            opacity: 0.5;
        }
        .iframeContentBtn:hover {
            opacity: 1;
        }
    }
    .newsContent {
        display: block;
        width: 1050px;
        height: 371px;
        background: #FFFFFF;
        border-radius: 6px;
        // border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        overflow: hidden;
        margin: 24px 0 0 50%;
        transform: translateX(-50%);
        .newsImg {
            float: left;
            width: 495px;
            height: 323px;
            margin: 24px 0px 24px 24px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .newsInfo {
            position: relative;
            float: left;
            width: 483px;
            height: 323px;
            margin: 24px 18px;
            div {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #282828;
                line-height: 25px;
            }
            .newsTitle {
                height: 68px;
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #282828;
                line-height: 34px;
            }
            .newsDate {
                position: absolute;
                left: 0;
                bottom: 0;
            }
            .newsSource {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }
    .newsContent:hover {
        position: relative;
        box-shadow: 0px 3px 24px 0px rgba(12,33,83,0.08), 0px 2px 6px 0px rgba(0,0,0,0.02);
        z-index: 2;
    }
    .newsPage {
        display: inline-block;
        margin: 48px 0 10px 50%;
        transform: translateX(-50%);
        .ant-pagination-item {
            width: 54px;
            height: 54px;
            background: unset;
            border: 0px;
            // box-shadow: 0px 3px 24px 0px rgba(12,33,83,0.08), 0px 2px 6px 0px rgba(0,0,0,0.04);
            border-radius: 100%;
            a {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #282828;
                line-height: 54px;
            }
        }
        .ant-pagination-item-active {
            box-shadow: 0px 3px 24px 0px rgba(12,33,83,0.08), 0px 2px 6px 0px rgba(0,0,0,0.04);
            background: #FFFFFF;
            a {
                color: #585858;
            }
        }
        .ant-pagination-prev {
            .ant-pagination-item-link {
                border: 0px;
                background: unset;
                path {
                    d:path("M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z")!important
                }
            }
        }
        .ant-pagination-next {
            .ant-pagination-item-link {
                border: 0px;
                background: unset;
                path {
                    d:path("M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z")!important
                }
            }
        }
    }
    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 48px;
    }    
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
        margin: 0 auto;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
        border: 0px;
    }
    .ant-tabs-top .ant-tabs-ink-bar-animated {
        height: 3px;
        background: #EB3A32;
    }
    .ant-tabs-tab {
        padding: 0;
    }
    .ant-tabs-tab-btn {
        font-size: 27px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #585858;
        line-height: 38px;
        padding: 0;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-size: 27px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #282828;
        line-height: 38px;
        padding: 0;
    }
}
@primary-color: #0099ff;@font-size-base: 14px;