.pc {
    &-container {
       // width: 375px;
       width: 100%;
        &-header {
          //  width: 375px;
            width: 100%;
            height: 261px;
            padding-left: 19px;
            position: relative;
            background-color: #f5f5f5;
            overflow: hidden;
            &-title {
                margin-top: 28px;
                font-size: 35px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #282828;
                line-height: 49px;
            }
            &-top {
                margin-top: 9px;
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #393939;
                line-height: 28px;
            }
            &-bottom {
                margin-top: 18px;
                width: 117px;
                height: 42px;
                background: #EB3A32;
                box-shadow: 0px 2px 9px 0px rgba(235,58,50,0.5);
                border-radius: 26px;
                backdrop-filter: blur(9px);
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                line-height: 42px;
                text-align: center;
                color: #E5E5E5;
                // a {
                //     color: #E5E5E5;
                // }
            }
            &-mount {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 205px;
                height: 70px;
                background: url('../../../官网切图/首页/banner山.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }
        &-pic {
            width: 339px;
            height: 300px;
            margin: 27px 12px 25px;
            // background: #EB3A32;
            background: url('../../../官网切图/首页/banner-mobile.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }
    &-body{
        margin:15px;
        margin-bottom: 40px;
        &-first{
            text-align: left;
            .adm-button{
                border:2px solid #EB3A32;
                color:#EB3A32;
                border-radius:6px;
                margin-top: 15px;

            }
            &-icon{
                margin:20px auto 40px auto;;
                background: url('../../../官网切图/PC独立站/编组2.png');
                //background-size: 100% 100%;
                bottom: 30px;
                height:250px;
                width:100%;
                background-size: cover;
            }
            
        }
        &-second{
            text-align: right;
            margin-right: 20px;
            .adm-button{
                overflow:hidden;
                border:2px solid #EB3A32;
                color:#EB3A32;
                border-radius:6px;
                margin-top: 30px;
            }
            &-icon{
                margin-right:50px;
                margin-bottom:40px;
                background: url('../../../官网切图/PC独立站/编组.png');
                //background-size: 100% 100%;
                bottom: 30px;
                height:370px;
                width:100%;
                background-size: cover;
            }

            
        }
        &-title{
            margin-top: 47px auto 20px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #232323;
            line-height: 30px;
            //text-align: center;
        }
        :nth-child(3){
            text-align: center;
        }
        &-tip{
            height: 19px;
            margin-top: 9px;
            font-size: 17px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #585858;
            line-height: 19px;
        }
        
        &-third{
            margin: 0 auto;
            width: 350px;
            display:flex;
            flex-wrap: wrap;
            &-icons{
                width:33.3%;
                margin-top: 20px;
                &-icon{
                    margin:0 auto;
                    width: 55px;
                    height: 55px;
                    background-image: url('../../../官网切图/PC独立站/入驻.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    // border: 1px dashed #000000;
                }
                &-tip {
                    height: 22px;
                    margin-top: 9px;
                    font-size: 17px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #232323;
                    line-height: 22px;
                    text-align: center;                    
                }
            }

            :nth-child(2){
                .pc-body-third-icons-icon{
                    background-image: url('../../../官网切图/PC独立站/2.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            :nth-child(3){
                .pc-body-third-icons-icon{
                    background-image: url('../../../官网切图/PC独立站/交易管理.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            :nth-child(4){
                .pc-body-third-icons-icon{
                    background-image: url('../../../官网切图/PC独立站/风控管理.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            :nth-child(5){
                .pc-body-third-icons-icon{
                    background-image: url('../../../官网切图/PC独立站/租后管理.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            :nth-child(6){
                .pc-body-third-icons-icon{
                    background-image: url('../../../官网切图/PC独立站/财务结算.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
        }
    }
    .pc-service{
        padding-top: 0px;
        width:100%;
    }
    &-service{
        margin:0;
        height:150px;
       // width:375px;
        width:100%;
        background-image: url('../../../官网切图/关于我门/banner-mobile.png');
        background-repeat:no-repeat;
        background-size: cover;
        overflow: hidden;

        &-title{
            text-align: center;
            padding-top: 18px;
            letter-spacing: 1.5px;
            font-size: 20px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 60px;
        }
        &-button{
            text-align: center;
            .adm-button{
                color: #EB3A32 ;
                letter-spacing: 1.5px;
            
            }
        }
    }
    .modalInquiry{
        border:2px solid white;
        backdrop-filter: none;
        .pic-box{
            border-radius: 16px;
            text-align: center;
            .pic{
              margin:20px auto;
              width: 230px;
              height: 230px;
              background-image: url('../../../官网切图/首页/客服经理.png');
              background-size: 100% 100%;
            }
            .tip{
              text-align: center;
              font-size: 21px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              line-height: 20px;
            }
        }
        .content{
            margin-top: 10px;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            // line-height: 40px;
        }
    }
}
@primary-color: #0099ff;@font-size-base: 14px;