.abboutPage{
  min-width: 1440px;
  .header-title{
    height: 450px;
    background: hsl(0, 0%, 97%);
    .header-pic{
      position: relative;
      width: 1440px;
      height: 450px;
      margin: 0 auto;
      background-image: url('../../../官网切图/关于我门/椭圆形.png');
      background-size: 100% 100%;
      .mount{
        position: absolute;
        left: 48px;
        bottom: 13px;
        width: 282px;
        height: 97px;
        background-image: url('../../../官网切图/首页/banner山.png');
        background-size: 100% 100%;
      }
    }
    .title{
      padding-top: 127.5px;
      // margin-left: 517.5px;
      width: 405px;
      margin: 0 auto;
      font-size: 45px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
  .abbout{
    padding-top: 90px;
    .abbout-title{
      text-align: center;
      font-size: 27px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #232323;
      line-height: 40.5px;
    }
    .abbout-tip{
      margin-top: 6px;
      margin-bottom: 48px;
      text-align: center;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #AEAEAE;
      line-height: 27px;
    }
    .abbout-content{
      margin: 0 auto;
      width: 1050px;
      height: 800px;
      background: #FFFFFF;
      box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08);
      border-radius: 17px;
      .content-top{
        float: left;
        text-align: center;
        .left-pic{
          width: 1050px;
          height: 131px;
          background-image: url('../../../官网切图/关于我门/位图.png');
          background-size: 100% 100%;
        }
        .left-icon{
          margin-top: -39px;
          margin-left: 486px;
          width: 78px;
          height: 78px;
          border-radius: 50%;
          background: #FFFFFF;
          box-shadow: 0px 1px 0px 0px rgba(59, 111, 239, 0.47);
          background-image: url('../../../官网切图/关于我门/LOGO.png');
          background-size: 100% 100%;
        }
        .tip{
          margin-top: 18px;
          height: 23px;
          font-size: 17px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #393939;
          line-height: 23px;
        }
      }
      .content-bottom{
        padding: 24px 60px;
        .title{
          margin-top: 205px;
          height: 38px;
          font-size: 27px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #232323;
          line-height: 38px;
          text-align: center;
          .borderLine{
            margin: -16px auto 0;
            width: 108px;
            height: 11px;
            background: rgba(235, 58, 50, 0.5);
          }
        }
        .content{
          margin-top: 18px;
          width: 930px;
          height: 420px;
          font-size: 21px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #393939;
          line-height: 30px;
          letter-spacing: 2px;      
          .text {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .boos{
    padding-top: 90px;
    padding-bottom: 105px;
    .boos-title{
      text-align: center;
      font-size: 27px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #232323;
      line-height: 40.5px;
    }
    .boos-tip{
      margin-top: 6px;
      margin-bottom: 48px;
      text-align: center;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #AEAEAE;
      line-height: 27px;
    }
    .boos-content{
      margin: 0 auto;
      width: 1050px;
      height: 390px;
      // background: chartreuse;
      .boos-left{
        float: left;
        width: 240px;
        height: 299px;
        background-color: #ffffff;
        .pic-hover{
          width: 240px;
          height: 299px;
          background: url('../../../官网切图/首页/李.jpg');
          background-position: -19px -30px;
          background-size: 110% 110%;
          box-shadow: 0px 0px 19px 0px rgba(43,2,1,0.12);
          border-radius: 10px;
          cursor: pointer;
          &:hover{
            .hover{
              display: block;
            }
          }
          .hover{
            position: relative;
            display: none;
            width: 240px;
            height: 299px;
            border-radius: 10px;
            background: rgba(13, 13, 13, 0.75);
            opacity: 0.75;
            .title{
              position: absolute;
              left: 19px;
              top: 182px;
              font-size: 18px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 27px;
            }
            .tip{
              position: absolute;
              padding-right: 10px;
              left: 19px;
              top: 213.5px;
              font-size: 15px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 22.5px;
            }
          }
        }
        .content{
          .name{
            margin-top: 24px;
            height: 34px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #232323;
            line-height: 34px;
            text-align: center;
          }
          .tip{
            margin-top: 6px;
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393939;
            line-height: 25px;
            text-align: center;
          }
        }
      }
      .boos-center{
        margin-left: 29px;
        float: left;
        width: 240px;
        height: 299px;
        background-color: #ffffff;
        .pic-hover{
          width: 240px;
          height: 299px;
          background: url('../../../官网切图/首页/杨.jpg');
          background-size: 100% 100%;
          box-shadow: 0px 0px 19px 0px rgba(43,2,1,0.12);
          border-radius: 10px;
          cursor: pointer;
          &:hover{
            .hover{
              display: block;
            }
          }
          .hover{
            position: relative;
            display: none;
            width: 240px;
            height: 299px;
            border-radius: 10px;
            background: rgba(13, 13, 13, 0.75);
            opacity: 0.75;
            .title{
              position: absolute;
              left: 19px;
              top: 182px;
              font-size: 18px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 27px;
            }
            .tip{
              position: absolute;
              padding-right: 10px;
              left: 19px;
              top: 213.5px;
              font-size: 15px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 22.5px;
            }
          }
        }
        .content{
          .name{
            margin-top: 24px;
            height: 34px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #232323;
            line-height: 34px;
            text-align: center;
          }
          .tip{
            margin-top: 6px;
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393939;
            line-height: 25px;
            text-align: center;
          }
        }
      }
      .boos-right{
        float: left;
        margin-left: 29px;
        width: 240px;
        height: 299px;
        background-color: #ffffff;
        .pic-hover{
          width: 240px;
          height: 299px;
          background: url('../../../官网切图/关于我门/王.jpg');
          background-size: 100%;
          background-position-y: -15px;
          box-shadow: 0px 0px 19px 0px rgba(43,2,1,0.12);
          border-radius: 10px;
          cursor: pointer;
          &:hover{
            .hover{
              display: block;
            }
          }
          .hover{
            position: relative;
            display: none;
            width: 240px;
            height: 299px;
            border-radius: 10px;
            background: rgba(13, 13, 13, 0.75);
            opacity: 0.75;
            .title{
              position: absolute;
              left: 19px;
              top: 182px;
              font-size: 18px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 27px;
            }
            .tip{
              position: absolute;
              padding-right: 10px;
              left: 19px;
              top: 213.5px;
              font-size: 15px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 22.5px;
            }
          }
        }
        .content{
          .name{
            margin-top: 24px;
            height: 34px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #232323;
            line-height: 34px;
            text-align: center;
          }
          .tip{
            margin-top: 6px;
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393939;
            line-height: 25px;
            text-align: center;
          }
        }
      }
      .boos-zhou{
        float: left;
        margin-left: 29px;
        width: 240px;
        height: 299px;
        background-color: #ffffff;
        .pic-hover{
          width: 240px;
          height: 299px;
          background: url('../../../官网切图/关于我门/周.jpg');
          background-size: 100% 100%;
          box-shadow: 0px 0px 19px 0px rgba(43,2,1,0.12);
          border-radius: 10px;
          cursor: pointer;
          &:hover{
            .hover{
              display: block;
            }
          }
          .hover{
            position: relative;
            display: none;
            width: 240px;
            height: 299px;
            border-radius: 10px;
            background: rgba(13, 13, 13, 0.75);
            opacity: 0.75;
            .title{
              position: absolute;
              left: 19px;
              top: 182px;
              font-size: 18px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 27px;
            }
            .tip{
              position: absolute;
              padding-right: 10px;
              left: 19px;
              top: 213.5px;
              font-size: 15px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 22.5px;
            }
          }
        }
        .content{
          .name{
            margin-top: 24px;
            height: 34px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #232323;
            line-height: 34px;
            text-align: center;
          }
          .tip{
            margin-top: 6px;
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393939;
            line-height: 25px;
            text-align: center;
          }
        }
      }
    }
  }
  .trial{
    height: 378px;
    background-image: url('../../../官网切图/关于我门/编组44.png');
    background-size: 100% 100%;
    .trial-center{
      position: relative;
      width: 1440px;
      height: 378px;
      margin: 0 auto;
      .trial-title{
        padding-top: 71px;
        // padding-bottom: 101px;
        text-align: center;
        height: 53px;
        font-size: 38px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #EB3A32;
        line-height: 53px;
      }
      .trial-button{
        margin: 154px auto 0;
        width: 177px;
        height: 60px;
        background: #EB3A32;
        border: 2px solid #EB3A32;
        box-shadow: 0px 3px 12px 0px rgba(235,58,50,0.5);
        font-size: 27px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 56px;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
      }
      .pic-r{
        position: absolute;
        right: 26px;
        bottom: 0;
        width: 449px;
        height: 320px;
        background-image: url('../../../官网切图/关于我门/编组45.png');
        background-size: 100% 100%;
      }
      .pic-l{
        position: absolute;
        left: 104px;
        bottom: 0;
        width: 270px;
        height: 300px;
        background-image: url('../../../官网切图/关于我门/编组46.png');
        background-size: 100% 100%;
      }
    }
  }
  .abbout-footer{
    margin-top: 150px;
    height: 333px;
    background-image: url('../../../官网切图/关于我门/底部装饰.png');
    background-size: 100% 100%;
    margin-bottom: -150px;
    padding-top: 67.5px;
    .footer-title{
      text-align: center;
      font-size: 30px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 45px;
      margin-bottom: 67.5px;
    }
    .footer-content{
      margin: 0 auto;
      width: 1050px;
      .left{
        float: left;
        width: 330px;
        height: 108px;
        padding-left: 18px;
        padding-top: 27px;
        background: #FFFFFF;
        box-shadow: 0px 3px 12px 0px rgba(145,13,8,0.5);
        border-radius: 8px;
        .c-left{
          font-size: 18px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #232323;
          line-height: 21px;
          margin-bottom: 9px;
        }
        .left-content{
          font-size: 21px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #585858;
          line-height: 31.5px;
        }
      }
      .center{
        float: left;
        width: 330px;
        height: 108px;
        padding-left: 18px;
        padding-top: 27px;
        background: #FFFFFF;
        box-shadow: 0px 3px 12px 0px rgba(145,13,8,0.5);
        border-radius: 8px;
        margin-left: 30px;
        .c-center{
          font-size: 18px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #232323;
          line-height: 21px;
          margin-bottom: 9px;
        }
        .center-content{
          font-size: 21px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #585858;
          line-height: 31.5px;
        }
      }
      .right{
        float: left;
        width: 330px;
        height: 108px;
        padding-left: 18px;
        padding-top: 27px;
        background: #FFFFFF;
        box-shadow: 0px 3px 12px 0px rgba(145,13,8,0.5);
        border-radius: 8px;
        margin-left: 30px;
        .c-right{
          font-size: 18px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #232323;
          line-height: 21px;
          margin-bottom: 9px;
        }
        .right-content{
          font-size: 21px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #585858;
          line-height: 31.5px;
        }
      }
    }
  }
  .modalReport{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      width: 600px;
      height: 500px;
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #ffffff;
      }
  } 
  .ant-modal-header{
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
    border-radius: 8px;
  }
  .ant-modal-title {
    font-size: 27px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 30px;
  }
  .ant-modal-body{
    // padding-top: 12px;
    .title{
      text-align: center;
      font-size: 27px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      margin-bottom: 18px;
      // line-height: 40.5px;
    }
    .icon{
      text-align: center;
      margin: 0 auto;
      width: 300px;
      height: 330px;
      background: #FFFFFF;
      border-radius: 16px;
      border: 2px solid #FFFFFF;
      padding-top: 30px;
      .icon-pic{
        margin: 0 auto;
        width: 240px;
        height: 240px;
        background-image: url('../../../官网切图/二维码/风控报告.png');
        background-size: 100% 100%;
      }
      .icon-title{
        margin-top: 10px;
        font-size: 21px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #3B6FEF;
        line-height: 31.5px;
      }
    }
    .icon-tip{
      margin-top: 14px;
      font-size: 21px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 31.5px;
      text-align: center;
    }
  }
  }
  .modalRevice{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      margin: 0 auto;
      width: 930px;
      height: 390px;
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #ffffff;
      }
      .background{
        .title{
          font-size: 27px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 40.5px;
          text-align: center;
        }
        .icons{
          margin: 0 auto;
          width: 876px;
          height: 270px;
          margin-left: 26px;
          margin-top: 24px;
          .icon-left{
            float: left;
            width: 240px;
            height: 270px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .left-pic{
              width: 192px;
              height: 192px;
              margin-top: 18px;
              margin-left: 24px;
              background-image: url("../../../官网切图/首页/二维码.png");
              background-size: 100% 100%;
            }
            .left-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #3B6FEF;
              line-height: 31.5px;
            }
          }
          .icon-center{
            margin-left: 54px;
            float: left;
            width: 240px;
            height: 270px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .center-pic{
              width: 192px;
              height: 192px;
              margin-top: 18px;
              margin-left: 24px;
              background-image: url("../../../官网切图/二维码/IOS.png");
              background-size: 100% 100%;
            }
            .center-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #3B6FEF;
              line-height: 31.5px;
              .tip-small{
                margin-top: -10px;
                font-size: 10px;
              }
            }
          }
          .icon-right{
            margin-left: 54px;
            float: left;
            width: 240px;
            height: 270px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .right-pic{
              width: 192px;
              height: 192px;
              margin-top: 18px;
              margin-left: 24px;
              background-image: url("../../../官网切图/二维码/安卓.png");
              background-size: 100% 100%;
            }
            .right-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #3B6FEF;
              line-height: 31.5px;
            }
          }
        }
      }
    } 
  }
  .modalLease{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      margin: 0 auto;
      width: 660px;
      height: 770px;
      box-shadow: 0px 0px 4px 4px rgba(57, 59, 65, 0.1);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #ffffff;
      }
      .modalContainer{
        .title{
          font-size: 24px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 36px;
          text-align: center;
        }
        .title-top{
          margin-left: 18px;
          margin-top: 30px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 27px;
        }
        .icons{
          margin-left: 18px;
          .iconTop{
            height: 90px;
            margin-top: 12px;
            .left{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              border: 2px solid #ffffff;
              cursor: pointer;
              .left-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_bijiben @2x.png");
                background-size: 100% 100%;
              }
              .left-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
            .center{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              border: 2px solid #ffffff;
              margin-left: 18px;
              .center-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_taishiji @2x.png");
                background-size: 100% 100%;
              }
              .center-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
            .right{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #ffffff;
              .right-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_dayinji @2x.png");
                background-size: 100% 100%;
              }
              .right-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
          }
          .iconBottom{
            height: 90px;
            margin-top: 18px;
            .left{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              border: 2px solid #ffffff;
              .left-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_shouji @2x.png");
                background-size: 100% 100%;
              }
              .left-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
            .center{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #ffffff;
              .center-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_touyingyi @2x.png");
                background-size: 100% 100%;
              }
              .center-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
            .right{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #ffffff;
              .right-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_其他服务@2x.png");
                background-size: 100% 100%;
              }
              .right-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
          }
        }
        .title-center{
          margin-left: 18px;
          margin-top: 30px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 27px;
        }
        .textArea{
          margin-left: 18px;
          margin-top: 12px;
          width: 576px;
          height: 198px;
          background: rgba(216, 216, 216, 0.55);
          border-radius: 8px;
          border: 2px solid #FFFFFF;
          overflow: hidden;
          .TextArea{
            .ant-input{
              min-height: 170px;
              resize: none;
              &::-webkit-input-placeholder{
                font-size: 18px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 27px;
                letter-spacing: 2px;
              }
            }
          }
        }
        .form{
          margin-left: 18px;
          margin-top: 30px;
          .title-bottom{
            float: left;
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 36px;
          }
          .input{
            float: left;
            width: 300px;
            height: 36px;
            background: rgba(216, 216, 216, 0.55);
            border-radius: 8px;
            border: 2px solid #FFFFFF;
            overflow: hidden;
            .input-child{
              border: 0px;
              outline: none;
              width: 100%;
              height: 100%;
              background: rgba(216, 216, 216, 0.55);
              &::-webkit-input-placeholder{
                font-size: 16.5px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 33px;
              }
            }
          }
        }
        .button{
          float: left;
          margin-top: 6px;
          margin-left: 150px;
          width: 330px;
          height: 60px;
          text-align: center;
          background: #3B6FEF;
          border-radius: 48px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 50px;
        }
      }
    }
  }
  .modalAppointment{
    .ant-modal-content{
      width: 570px;
      height: 417px;
      background: #FFFFFF;
      border-radius: 8px;
      .title{
        text-align: center;
        font-size: 27px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #232323;
        line-height: 54px;
      }
      .form-div{
        width: 360px;
        margin-left: 88.5px;
        margin-top: 30px;
        font-size: 18px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #232323;
        height: 36px;
        // line-height: 36px;
        .span{
          float: left;
          margin-top: 6px;
          display: block;
          height: 25px;
          line-height: 25px;
        }
        .inputs{
          float: left;
          display: block;
          width: 255px;
          height: 36px;
          border-radius: 6px;
          border: 1px solid #979797;
          overflow: hidden;
          .input{
            outline: none;
            border: 0px;
            width: 100%;
            height: 100%;
          }
        }
      }
      .buttonBox{
        .button{
          margin-left: 201px;
          margin-top: 24px;
          width: 120px;
          height: 42px;
          background: #EB3A32;
          border-radius: 8px;
          font-size: 18px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          border: none;
          cursor: pointer;
        }
      }

    }
  }
}

@primary-color: #0099ff;@font-size-base: 14px;