.indexContent{
  min-width: 1440px;
ul{
  margin: 0;
  padding: 0;
  list-style-type: none;
}
//左边盒子部分
.leftBox{
  z-index: 10;
  position: fixed;
  top: 300px;
  // top: 100px;
  left: 12px;
  // width: 180px;
  // height: 172px;
  width: 267px;
  height: 320px;
  background: rgba(255, 255, 255, 0.79);
  box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08), 0px 0px 12px 0px rgba(43,2,1,0.12);
  border-radius: 8px;
  border: 1px solid #FFFFFF;
  backdrop-filter: blur(10px);
  .icon{
    padding-top: 14px;
    padding-right: 14px;
    text-align: right;
    font-size: 18px;
  }
  .title{
    text-align: center;
    height: 38px;
    font-size: 27px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #232323;
    line-height: 38px;
    text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
    margin: -10px 0 18px;
  }
  .pic{
    margin: 0 auto;
    width: 168px;
    height: 168px;
    background: url("../../官网切图/首页/消费者业务二维码.png");
    background-size: 100% 100%;
  }
  .tip{
    margin-top: 18px;
    text-align: center;
    height: 30px;
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #393939;
    line-height: 30px;
    text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
  }
}

//固定盒子部分
.fixBox{
  z-index: 10;
  top: 100px;
  right: 12px;
  // width: 78px;
  height: 300px;
  position: fixed;
  .box-right{
    .top{
      width: 78px;
      height: 93px;
      background-image: url('../../官网切图/首页/客服悬浮窗/客服.png');
      background-position: 137px 152px;
      background-size: 252%;
    }
    .box{
      backdrop-filter: blur(10px);
      margin-top: -15px;
      margin-left: 6px;
      width: 66px;
      height: 21px;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 3px;
      .tip{
        text-align: center;
        line-height: 21px;
        color: #ffffff;
        font-size: 12px;
      }
    }
    .lease{
      margin-top: 6px;
      width: 78px;
      height: 78px;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 6px;
      padding-top: 15px;
      .pic{
        margin-left: 24px;
        width: 30px;
        height: 30px;
        background-image: url('../../官网切图/首页/客服悬浮窗/编组 18.png');
        background-size: 100% 100%;
      }
      .font{
        text-align: center;
        margin-top: 6px;
        font-size: 13.5px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 21px;
      }
      .box-lease{
        backdrop-filter: blur(10px);
        display: none;
        position: fixed;
        right: 102px;
        top: 100px;
        width: 336px;
        height: 431px;
        background: rgba(255, 255, 255, 0.79);
        box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08), 0px 0px 12px 0px rgba(43,2,1,0.12);
        border-radius: 8px;
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(10px);
        .title{
          margin-top: 48px;
          margin-left: 120px;
          font-size: 27px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #232323;
          line-height: 38px;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
        }
        .pic{
          margin-top: 18px;
          margin-left: 55px;
          width: 231px;
          height: 231px;
          background-image: url('../../官网切图/首页/消费者业务二维码.png');
          background-size: 100% 100%;
        }
        .tip{
          margin-top: 18px;
          height: 30px;
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #393939;
          line-height: 30px;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
          text-align: center;
        }
      }
      cursor: pointer;
      &:hover{
        background: #EB3A32;
        .box-lease{
          display: block;
        }
      }
    }
    .switch{
      margin-top: 18px;
      margin-bottom: -156px;
      width: 78px;
      height: 156px;
      background: rgba(0, 0, 0, 0.7);
      box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08);
      border-radius: 5px;
      backdrop-filter: blur(6px);
    }
    .wechat{
      position: relative;
      width: 78px;
      height: 78px;
      // background: rgba(0, 0, 0, 0.7);
      border-radius: 5px;
      padding-top: 15px;
      z-index: 1;
      .pic{
        margin-left: 24px;
        width: 30px;
        height: 30px;
        background-image: url('../../官网切图/首页/客服悬浮窗/微信 (2).png');
        background-size: 100% 100%;
      }
      .font{
        text-align: center;
        margin-top: 6px;
        font-size: 13.5px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 21px;
      }
      cursor: pointer;
      &:hover{
        background: #EB3A32;
        .box-wechat{
          display: block;
        }
      }
      .box-wechat{
        backdrop-filter: blur(10px);
        display: none;
        position: fixed;
        right: 102px;
        top: 100px;
        width: 336px;
        height: 431px;
        background: rgba(255, 255, 255, 0.79);
        box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08), 0px 0px 12px 0px rgba(43,2,1,0.12);
        border-radius: 8px;
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(10px);
        .title{
          margin-top: 48px;
          margin-left: 114px;
          height: 38px;
          font-size: 27px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #232323;
          line-height: 38px;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
        }
        .tip{
          text-align: center;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #393939;
          line-height: 17px;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
        }
        .pic{
          margin-top: 18px;
          margin-left: 55px;
          width: 231px;
          height: 231px;
          background-image: url('../../官网切图/二维码/微信客服.png');
          background-size: 100% 100%;
        }
        .content{
          margin-top: 18px;
          text-align: center;
          height: 30px;
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #393939;
          line-height: 30px;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
        }
      }
    }
    .phone{
      position: relative;
      z-index: 1;
      // margin-top: 6px;
      width: 78px;
      height: 78px;
      // background: rgba(0, 0, 0, 0.7);
      border-radius: 5px;
      padding-top: 15px;
      .pic{
        margin-left: 24px;
        width: 30px;
        height: 30px;
        background-image: url('../../官网切图/首页/客服悬浮窗/编组 11.png');
        background-size: 100% 100%;
      }
      .font{
        text-align: center;
        margin-top: 6px;
        font-size: 13.5px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 21px;
      }
      &:hover{
        background: #EB3A32;
        .box-phone{
          display: block;
        }
      }
      cursor: pointer;
      .box-phone{
        backdrop-filter: blur(10px);
        display: none;
        position: fixed;
        right: 102px;
        top: 382px;
        width: 373px;
        height: 78px;
        background: rgba(255, 255, 255, 0.79);
        box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08), 0px 0px 12px 0px rgba(43,2,1,0.12);
        border-radius: 8px;
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(10px);
        .top1{
          font-size: 13.5px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #232323;
          line-height: 48px;
          padding-top: 30px;
          .big{
            font-size: 24px;
            margin-left: 30px;
            // margin-top: 39px;
          }
        }
        .bottom{
          background-color:transparent;
          margin: 29px 0px 29px 30px;
          font-size: 18px;
          font-family: AppleSystemUIFont;
          color: #232323;
          line-height: 21px;
          text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
          .small{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #232323;
            line-height: 19px;
            text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
          }
        }
      }
    }
  }



}
.modalLease{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      width: 600px;
      height: 500px;
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
  } 
  .ant-modal-header{
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
    border-radius: 8px;
  }
  .ant-modal-title {
    font-size: 27px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 30px;
  }
  .ant-modal-body{
    // padding-top: 12px;
    .title{
      text-align: center;
      font-size: 27px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      margin-bottom: 18px;
      // line-height: 40.5px;
    }
    .icon{
      text-align: center;
      margin: 0 auto;
      width: 300px;
      height: 330px;
      background: #FFFFFF;
      border-radius: 16px;
      border: 2px solid #FFFFFF;
      padding-top: 30px;
      .icon-pic{
        margin: 0 auto;
        width: 240px;
        height: 240px;
        background-image: url('../../官网切图/首页/消费者业务二维码.png');
        background-size: 100% 100%;
      }
      .icon-title{
        margin-top: 10px;
        font-size: 21px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #000000;
        line-height: 31.5px;
      }
    }
  }
  
}

.container{
  min-width: 1440px;
  margin: 0 auto;
  height: 540px;
  // width: 1440px;
  background: #F8F8F8;
  position: relative;
  overflow: hidden;
  .info {
    margin-top: -90px;
    height: 90px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.08);
    backdrop-filter: blur(10px);
    .info-center{
      display: inline-flex;
      margin: 0 0 0 50%;
      transform: translateX(-50%);
      .news-container{
        display: flex;
        .news-card{
          width: 263px;
          height: 89px;
          padding: 21px 18px 21px;
          display: flex;
          align-items: center;
          &:hover{
            .news-title{
              color: #EB3A32
            }
          }
          .news-title{
            // height: 17px;
            max-height: 34px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #232323;
            line-height: 17px;
            margin: 0;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;					
            -webkit-box-orient: vertical;
          }
          .news-content{
            margin-top: 6px;
            width: 227px;
            height: 15px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #585858;
            line-height: 15px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;				//溢出内容隐藏
            text-overflow: ellipsis;		//文本溢出部分用省略号表示
            display: -webkit-box;			//特别显示模式
            -webkit-line-clamp: 1;			//行数
            line-clamp: 1;					
            -webkit-box-orient: vertical;	//盒子中内容竖直排列
          }
        }
        .news-line{
          width: 1px;
          height: 47px;
          background: #000000;
          opacity: 0.08;
          margin: 21px 0;
        }
      }
    }
  }
  
  .header{
    position: relative;
    width: 1440px;
    height: 600px;
    overflow: hidden;
    margin: -60px auto 0;
    background: url('../../官网切图/首页/banner 主图.png');
    background-size: 100% 100%;
    .top-title{
      margin-left: 195px;
      margin-top: 142.5px;
      font-size: 48px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #282828;
      line-height: 72px;
    }
    .top-content-top{
      margin-left: 195px;
      margin-top: 18px;
      // width: 417px;
      font-size: 30px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      line-height: 44px;
    }
    .top-content-center{
      margin-left: 195px;
      margin-top: 18px;
      // width: 336px;
      height: 19px;
      font-size: 19px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      line-height: 19px
    }
    .top-content-bottom{
      margin-left: 195px;
      margin-top: 30px;
      width: 210px;
      height: 66px;
      background: #EB3A32;
      box-shadow: 0px 2px 4px 0px #F2AAA8;
      border-radius: 33px;
      border: 3px solid #EB3A32;
      // filter: blur(9px);
      text-align: center;
      line-height: 60px;
      font-size: 36px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
      a{
        color: #FFFFFF;
      }
      &:hover{
        border: 3px solid rgba(255, 255, 255, 0.3);
      }
    }
    .pic-left{
      position: absolute;
      width: 282px;
      height: 96px;
      background: url('../../官网切图/首页/banner山.png');
      background-size: 100% 100%;
      left: 48px;
      bottom: 30px;
    }
    // .pic-right{
    //   position: absolute;
    //   width: 460px;
    //   height: 408px;
    //   background: url('../../官网切图/首页/banner 主图.png');
    //   background-size: 100% 100%;
    //   top: 98px;
    //   bottom: 94px;
    //   right: 130px;
    // }
  }
}


.container1{
  margin: 0 auto;
  display: flex;
  justify-content: center;
  // align-items: center;
  text-align: center;

  // 数据展示部分
  .data-title{
    margin-top: 91px;
    width: 1050px;
    height: 296px;
    background: #FFFFFF;
    box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08);
    border-radius: 9px;
    // background-image: url('../../官网切图/首页/顶部数据展示装饰.png');
    background-size: 100% 100%;
    .data-time{
      margin-top: 30px;
      height: 44px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #282828;
      line-height: 25px;
      .time{
        font-weight: bolder;
        font-size: 40px;
        line-height: 44px;
      }
    }
    .hr{
      margin-top: 15px;
      border: 1px dashed #666666;
      visibility: hidden;
    }
    .data-datas{
      margin-left: 57px;
      .line{
        float: left;
        margin-top: 60px;
        margin-left: 35px;
        width: 2px;
        height: 90px;
        background: #000000;
        opacity: 0.07;
      }
      .data-data{
        float: left;
        margin-top: 20px;
        margin-left: 35px;
        width: 180px;
        height: 180px;
        border: 12px solid rgba(255, 23, 13, 0.08);
        // background: #ECF1FF;
        border-radius: 50%;
        .data-span{
          .data-span-font{
            padding-top: 45px;
            display: block;
            font-size: 36px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #282828;
            line-height: 50px;
          }
          .data-span1{
            display: block;
            text-align: center;
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393939;
            line-height: 25px;
          }
        }
      }
    }
  }
}
//核心产品部分
.product{
  margin-top: 90px;
  text-align: center;
  .product-title{
    font-size: 27px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #232323;
    line-height: 38px
  }
  .hr-orange{
    width: 180px;
    height: 2px;
    background: #EF983B;
    border: 0px;
    // margin: 0 auto;
    // width: 180px;
    // height: 2px;
    // background: #EF983B;
    // padding-bottom:12px ;
    // background: linear-gradient(180deg, #EF983B 0%, #EF983B 100%, #EF983B 0%);
    
  }
  .product-title-bottom{
    font-size: 19px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #393939;
    line-height: 30px;
  }
  .product-icon{
    padding-top:48px ;
    height: 150px;
    width: 792px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
    .store-box{
      &:hover{
        .store{
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #282828;
          line-height: 31.5px;
          .triangle{
            display: block;
          }
        }
      }
      // .store-hover{
      //   // margin: 0 auto;
      //   margin-top: 100px;
      //   width: 1050px;
      //   height: 360px;
      //   background: #FFFFFF;
      //   box-shadow: 0px 2px 8px 0px rgba(59, 111, 239, 0.47);
      //   border-radius: 10px;
      //   text-align: left;
      // }
      .product-icon-store{
        width: 120px;
        height: 120px;
        background-image: url('../../官网切图/首页/店铺 icon.png');
        background-size: 100% 100%;
      }
      .store{
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #232323;
        line-height: 27px;
        .triangle{
          display: none;
          margin: 0 auto;
          width: 0;
          height: 0;
          border-top: 12px solid #EB3A32;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent; 
        }
      }
    }
    .app-box{
      &:hover{
        .app{
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #282828;
          line-height: 31.5px;
          .triangle{
            display: block;
          }
        }
      }
      .app{
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #232323;
        line-height: 27px;
        .triangle{
          display: none;
          margin: 0 auto;
          width: 0;
          height: 0;
          border-top: 12px solid #EB3A32;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent; 
        }
      }
      .product-icon-app{
        width: 120px;
        height: 120px;
        background-image: url('../../官网切图/首页/小程序 icon.png');
        background-size: 100% 100%;
        // margin-left: 216px;
        cursor: pointer;
      }
      // .product-content-app{
      //   // margin-left: 216px;
      // }
    }
    .pc-box{
      &:hover{
        .pc{
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #282828;
          line-height: 31.5px;
          .triangle{
            display: block;
          }
        }
      }
      .product-icon-pc{
        width: 120px;
        height: 120px;
        background-image: url('../../官网切图/首页/PC icon备份.png');
        background-size: 100% 100%;
        // margin-left: 216px;
        cursor: pointer;
      }
      // .product-content-pc{
      //   margin-left: 216px;
      // }
      .pc{
        margin-top: 0;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #232323;
        line-height: 27px;
        .triangle{
          display: none;
          margin: 0 auto;
          width: 0;
          height: 0;
          border-top: 12px solid #EB3A32;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent; 
        }
      }
    }

  }
  .product-content-store{
    margin: 0 auto;
    margin-top: 100px;
    width: 1050px;
    height: 341px;
    background: #FFFFFF;
    box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08);
    border-radius: 10px;
    text-align: left;
    .product-content-title{
      display: inline-block;
      margin-top: 42px;
      margin-left: 54px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #232323;
      line-height: 34px;
    }
    .product-contents{
      margin-top: 36px;
      margin-left: 54px;
      li{
        margin-top: 12px;
        font-size: 18px;
        font-family: AppleSystemUIFont;
        color: #585858;
        line-height: 21px;
        .orangeCircle{
          display: inline-block;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #EF983B ;
        }
      }
    }
    .product-button{
      margin-top: 27px;
      .product-button-left{
        float: left;
        margin-left: 54px;
        width: 120px;
        height: 42px;
        // border: 1px solid blue;
        text-align: center;
        line-height: 42px;
        background-image: url('../../官网切图/首页/试用按钮.png');
        background-size: 100% 100%;
        border-radius: 10px;
        cursor: pointer;
        font-size: 21px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        a{
          color: #FFFFFF;
        }
      }
      .product-button-right{
        float: left;
        margin-left: 54px;
        width: 120px;
        height: 42px;
        text-align: center;
        line-height: 38px;
        cursor: pointer;
        border-radius: 8px;
        border: 2px solid #EB3A32;
        font-size: 21px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #EB3A32;
      }
    }
    .product-rightBox{
      .rightStore{
        margin-top: -259px;
        // margin-right: 24px;
        float: right;
        width: 500px;
        height: 341px;
        // border: 1px dashed #000000;
        background-image: url('../../官网切图/首页/租赁店铺.png');
        background-size: 100% 100%;
      }
      .rightApp{
        margin-top: -226px;
        // margin-right: 72px;
        float: right;
        width: 500px;
        height: 341px;
        // border: 1px dashed #000000;
        background-image: url('../../官网切图/首页/小程序.png');
        background-size: 100% 100%;
      }
      .rightPc{
        margin-top: -226px;
        // margin-right: 0;
        float: right;
        width: 500px;
        height: 341px;
        // border: 1px dashed #000000;
        background-image: url('../../官网切图/首页/PC独立站.png');
        background-size: 100% 100%;
      }
    }
  }
}
//三重保障部分
.guarantee{
  margin-top: 90px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.02);
  padding-top:90px ;
  height: 705px;
  .guarantee-title{
    font-size: 27px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #232323;
    line-height: 38px;
  }
  .hr-orange{
    width: 180px;
    height: 2px;
    background: #EF983B;
    border: 0;
  }
  .guarantee-title-bottom{
    font-size: 21px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393939;
    line-height: 30px;
  }
  .guarantee-content{
    padding-top: 48px;
    height: 390px;
    width: 1050px;
    display: flex;
    padding-bottom: 80px;
    justify-content: center;
    margin: 0 auto;
    .left-div{
      position: relative;
      cursor: pointer;
      &:hover{
        // .guarantee-content-left{
        //   display: none;
        // }
        .guarantee-content-left-hover{
          display: block;
        }
      }
    }
    //左边部分
    .guarantee-content-left{
      display: block;
      // height: 390px;
      background-color: #ffffff;
      width: 270px; 
      height: 390px;
      text-align: center;
      cursor: pointer;
      border-radius: 18px;
      overflow: hidden;
      .guarantee-left-icon{
        margin: 0 auto;
        // margin-top: 30px;
        width: 90px;
        height: 90px;
        background-image: url('../../官网切图/首页/免押金.png');
        background-size: 100% 100%;
      }
      .guarantee-left-title{
        display: inline-block;
        margin-top: 18px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
        line-height: 34px;
        .borderLine{
          display: block;
          margin-top: -12px;
          width: 120px;
          height: 9px;
          background: #A2BBF8;
        }
      }
      .guarantee-left-tip{
        display: inline-block;
        margin-top: 18px;
        width: 234px;
        // height: 72px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #585858;
        line-height: 25px;
        letter-spacing: 2px;
      }
      .guarantee-left-footer{
        margin-top: 31px;
        height: 90px;
        width: 270px;
        text-align: center;
        line-height: 90px;
        font-size: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #282828;
        letter-spacing: 2px;
        background-image: url('../../官网切图/首页/保障底部装饰.png');
        background-size: 100% 100%;
      }
    }
    //左边滑入后
    .guarantee-content-left-hover{
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      border-radius: 18px;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(5px);
      padding-top: 30px;
      width: 270px;
      height: 390px;
      overflow: hidden;
      cursor: pointer;
      .guarantee-left-title{
        display: block;
        text-align: center;
        // padding-top: 30px;
        font-size: 24px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 36px;
      }
      .guarantee-left-tip{
        text-align: left;
        margin: 0 auto;
        display: block;
        margin-top: 10px;
        width: 231px;
        height: 132px;
        font-size: 19.5px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 30px;
        letter-spacing: 1.5px;

      }
      .guarantee-left-footer{
        margin-top: 93px;
        height: 90px;
        width: 270px;
        text-align: center;
        line-height: 90px;
        font-size: 21px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        letter-spacing: 2px;
        // background-image: url('../../官网切图/首页/保障底部装饰.png');
        // background-size: 100% 100%;
        // background-color: #3B6FEF;
      }
    }
    .div{
      width: 120px;
    }
    .center-div{
      position: relative;
      cursor: pointer;
      &:hover{
        .guarantee-content-center{
          // display: none;
        }
        .guarantee-content-center-hover{
          display: block;
        }
      }
      //中间部分
      .guarantee-content-center{
        display: block;
        border-radius: 24px;
        // height: 390px;
        background-color: #ffffff;
        width: 270px;
        height: 390px;
        // margin-left: 120px;
        text-align: center;
        overflow: hidden;
        cursor: pointer;
        .guarantee-center-icon{
          margin: 0 auto;
          // margin-top: 30px;
          width: 90px;
          height: 90px;
          background-image: url('../../官网切图/首页/仲裁.png');
          background-size: 100% 100%;
        }
        .guarantee-center-title{
          display: inline-block;
          margin-top: 18px;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #232323;
          line-height: 34px;
          .borderLine{
            display: block;
            margin-top: -12px;
            width: 120px;
            height: 9px;
            background: #A2BBF8;
          }
        }
        .guarantee-center-tip{
          display: inline-block;
          margin-top: 18px;
          width: 234px;
          // height: 72px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #585858;
          line-height: 25px;
          letter-spacing: 2px;
        }
        .guarantee-center-footer{
          margin-top: 31px;
          height: 90px;
          width: 270px;
          text-align: center;
          line-height: 90px;
          font-size: 21px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #282828;
          letter-spacing: 2px;
          background-image: url('../../官网切图/首页/保障底部装饰.png');
          background-size: 100% 100%;
        }
      }
      //中间滑入后
      .guarantee-content-center-hover{
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        border-radius: 24px;
        height: 390px;
        background-color:  rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        width: 270px;
        // margin-left: 120px;
        text-align: center;
        overflow: hidden;
        cursor: pointer;
        .guarantee-center-title{
          display: block;
          text-align: center;
          padding-top: 30px;
          font-size: 24px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 36px;
        }
        .guarantee-center-tip{
          text-align: left;
          margin: 0 auto;
          display: block;
          margin-top: 10px;
          width: 231px;
          height: 132px;
          font-size: 19.5px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 30px;
          letter-spacing: 1.5px;
        }
        .guarantee-center-footer{
          margin-top: 93px;
          height: 90px;
          text-align: center;
          line-height: 90px;
          font-size: 21px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          letter-spacing: 2px;
        }
      }
    }
    .right-div{
      position: relative;
      cursor: pointer;
      &:hover{
        .guarantee-content-right{
          // display: none;
        }
        .guarantee-content-right-hover{
          display: block;
        }
      }
    }
    //右边部分
    .guarantee-content-right{
      // height: 390px;
      border-radius: 24px;
      background-color: #ffffff;
      width: 270px;
      height: 390px;
      // margin-left: 120px;
      overflow: hidden;
      text-align: center;
      .guarantee-right-icon{
        margin: 0 auto;
        // margin-top: 30px;
        width: 90px;
        height: 90px;
        background-image: url('../../官网切图/首页/区块链.png');
        background-size: 100% 100%;
      }

      .guarantee-right-title{
        display: inline-block;
        margin-top: 18px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
        line-height: 34px;
        .borderLine{
          display: block;
          margin-top: -12px;
          margin-left: 12px;
          width: 120px;
          height: 9px;
          background: #A2BBF8;
        }
      }
      .guarantee-right-tip{
        display: inline-block;
        margin-top: 18px;
        width: 234px;
        // height: 72px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #585858;
        line-height: 25px;
        letter-spacing: 2px;
      }
      .guarantee-right-footer{
        margin-top: 31px;
        height: 90px;
        width: 270px;
        text-align: center;
        line-height: 90px;
        font-size: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #282828;
        letter-spacing: 2px;
        background-image: url('../../官网切图/首页/保障底部装饰.png');
        background-size: 100% 100%;
      }
    }
    //中间滑入后
    .guarantee-content-right-hover{
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      border-radius: 24px;
      height: 390px;
      background-color:  rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(5px);
      width: 270px;
      // margin-left: 120px;
      text-align: center;
      overflow: hidden;
      cursor: pointer;
      .guarantee-right-title{
        display: block;
        text-align: center;
        padding-top: 30px;
        font-size: 24px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 36px;
      }
      .guarantee-right-tip{
        text-align: left;
        margin: 0 auto;
        display: block;
        margin-top: 10px;
        width: 231px;
        height: 132px;
        font-size: 19.5px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 30px;
        letter-spacing: 1.5px;
      }
      .guarantee-right-footer{
        margin-top: 93px;
        height: 90px;
        text-align: center;
        line-height: 90px;
        font-size: 21px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        letter-spacing: 2px;
      }
    }
  }
}
//业务升级部分
.business{
  width: 1050px;
  margin: 90px auto 0;
  height: 387px;
  .business-left{
    float: left;
    margin-left: 20px;
    width: 504px;
    height: 387px;
    background-image: url('../../官网切图/首页/全行业全场景.png');
    background-size: 100% 100%;
  }
  .business-right{
    float: left;
    // margin-left: 150px;
    .business-right-top{
      display: block;
      margin-top: 158px;
      margin-left: 105px;
      font-size: 27px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #232323;
      line-height: 40px;
    }
    .business-right-bottom{
      position: absolute;
      display: block;
      margin-top: 24px;
      margin-left: 105px;
      font-size: 20px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #393939;
      line-height: 30px;
    }
  }
}
//消费者业务
.consumption{
  margin-top: 90px;
  text-align: center;
  .consumption-title{
    font-size: 27px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #232323;
    line-height: 38px;
  }
  .hr-orange{
    width: 180px;
    height: 2px;
    background: #EF983B;
    border: 0;
  }
  .consumption-title-bottom{
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393939;
    line-height: 28px;
  }
  .consumption-content{
    margin: 0 auto;
    margin-top: 48px;
    width: 1050px;
    height: 720px;
    background-color: cornflowerblue;
    background-image: url('../../官网切图/首页/消费者业务/背景】.png');
    background-size: 100% 100%;
    .consumption-picFirst{
      .pic1-div{
        float: left;
        width: 525px;
        height: 360px;
        cursor: pointer;
        &:hover{
          .pic1{
            display: none;
          }
          .hover1{
            display: block;
          }
        }
        .pic1{
          width: 525px;
          height: 360px;
          background-image: url('../../官网切图/首页/消费者业务/手机.png');
          background-size: 100% 100%;
          cursor: pointer;
        }
        .hover1{
          display: none;
          background: rgba(0, 0, 0, 0.7);
          width: 525px;
          height: 360px;
          padding-top: 96px;
          backdrop-filter: blur(15px);
          .hover1-icon{
            margin-left: 172.5px;
            width: 180px;
            height: 180px;
            background-image: url('../../官网切图/首页/二维码.png');
            background-size: 100% 100%;
          }
          .hover1-font{
            margin-top: 12px;
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 27px;
            letter-spacing: 1.5px;
          }
        }
      }
      .pic2-div{
        float: left;
        width: 262.5px;
        height: 360px;
        cursor: pointer;
        &:hover{
          .pic2{
            display: none;
          }
          .hover2{
            display: block;
          }
        }
        .pic2{
          float: left;
          width: 262.5px;
          height: 360px;
          background-image: url('../../官网切图/首页/消费者业务/耳机.png');
          background-size: 100% 100%;
        }
        .hover2{
          display: none;
          backdrop-filter: blur(15px);
          background: rgba(0, 0, 0, 0.7);
          width: 262.5px;
          height: 360px;
          padding-top: 96px;
          .hover2-icon{
            margin-left: 41.25px;
            width: 180px;
            height: 180px;
            background-image: url('../../官网切图/首页/二维码.png');
            background-size: 100% 100%;
          }
          .hover2-font{
            margin-top: 12px;
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 27px;
            letter-spacing: 1.5px;
          }
        }
      }
      .pic3-div{
        float: left;
        width: 262.5px;
        height: 360px;
        cursor: pointer;
        &:hover{
          .pic3{
            display: none;
          }
          .hover3{
            display: block;
          }
        }
        .pic3{
          float: left;
          width: 262.5px;
          height: 360px;
          background-image: url('../../官网切图/首页/消费者业务/游戏机.png');
          background-size: 100% 100%;
        }
        .hover3{
          display: none;
          backdrop-filter: blur(15px);
          background: rgba(0, 0, 0, 0.7);
          width: 262.5px;
          height: 360px;
          padding-top: 96px;
          .hover3-icon{
            margin-left: 41.25px;
            width: 180px;
            height: 180px;
            background-image: url('../../官网切图/首页/二维码.png');
            background-size: 100% 100%;
          }
          .hover3-font{
            margin-top: 12px;
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 27px;
            letter-spacing: 1.5px;
          }
        }
      }
    }
    .consumption-picSecond{
      .pic4-div{
        float: left;
        width: 262.5px;
        height: 360px;
        cursor: pointer;
        &:hover{
          .pic4{
            display: none;
          }
          .hover4{
            display: block;
          }
        }
        .pic4{
          float: left;
          width: 262.5px;
          height: 360px;
          background-image: url('../../官网切图/首页/消费者业务/GO pro 9png.png');
          background-size: 100% 100%;
        }
        .hover4{
          backdrop-filter: blur(15px);
          display: none;
          background: rgba(0, 0, 0, 0.7);
          width: 262.5px;
          height: 360px;
          padding-top: 96px;
          .hover4-icon{
            margin-left: 41.25px;
            width: 180px;
            height: 180px;
            background-image: url('../../官网切图/首页/二维码.png');
            background-size: 100% 100%;
          }
          .hover4-font{
            margin-top: 12px;
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 27px;
            letter-spacing: 1.5px;
          }
        }
      }
      .pic5-div{
        float: left;
        width: 525px;
        height: 360px;
        cursor: pointer;
        &:hover{
          .pic5{
            display: none;
          }
          .hover5{
            display: block;
          }
        }
        .pic5{
          float: left;
          width: 525px;
          height: 360px;
          background-image: url('../../官网切图/首页/消费者业务/电脑.png');
          background-size: 100% 100%;
        }
        .hover5{
          display: none;
          backdrop-filter: blur(15px);
          background: rgba(0, 0, 0, 0.7);
          width: 525px;
          height: 360px;
          padding-top: 96px;
          .hover5-icon{
            margin-left: 172.5px;
            width: 180px;
            height: 180px;
            background-image: url('../../官网切图/首页/二维码.png');
            background-size: 100% 100%;
          }
          .hover5-font{
            margin-top: 12px;
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 27px;
            letter-spacing: 1.5px;
          }
        }
      }
      .pic6-div{
        float: left;
        width: 262.5px;
        height: 360px;
        cursor: pointer;
        &:hover{
          .pic6{
            display: none;
          }
          .hover6{
            display: block;
          }
        }
        .pic6{
          float: left;
          width: 262.5px;
          height: 360px;
          background-image: url('../../官网切图/首页/消费者业务/手表.png');
          background-size: 100% 100%;
        }
        .hover6{
          display: none;
          backdrop-filter: blur(15px);
          background: rgba(0, 0, 0, 0.7);
          width: 262.5px;
          height: 360px;
          padding-top: 96px;
          .hover6-icon{
            margin-left: 41.25px;
            width: 180px;
            height: 180px;
            background-image: url('../../官网切图/首页/二维码.png');
            background-size: 100% 100%;
          }
          .hover6-font{
            margin-top: 12px;
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 27px;
            letter-spacing: 1.5px;
          }
        }
      }
    }
  }
  .consumption-button{
    margin: auto;
    margin-top: 36px;
    width: 180px;
    height: 60px;
    line-height: 60px;
    background: #EB3A32;
    border-radius: 6px;
    font-size: 21px;
    font-family: AppleSystemUIFont;
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
  }
}
//联系我们部分
.contact{
  margin: 0 auto;
  text-align: center;
  .contact-title{
    margin-top: 90px;
    font-size: 27px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #232323;
    line-height: 38px;
  }
  .contact-content{
    margin: 0 auto;
    margin-top: 57px;
    width: 1050px;
    height: 404px;
    background: #FFFFFF;
    box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08);
    border-radius: 8px;
    background-image: url('../../官网切图/首页/客服人物.png');
    background-size: 100% 100%;
    .contact-content-left{
      float: left;
      text-align: left;
      .contact-left-title{
        margin-top: 30px;
        margin-left: 75px;
        font-size: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393939;
        line-height: 30px;
      }
      .contact-left-box{
        margin-top: 15px;
        margin-left: 75px;
        width: 431px;
        height: 282px;
        // border: 1px dashed #000000;
        .contact-box-title{
          width: 318px;
          // padding-left: 15px;
          // margin-left: -10px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #393939;
          line-height: 25px;
        }
      }
      .contact-left-phone{
        padding-left: 30px;
        margin-top: 16px;
        margin-left: 60px;
        height: 21px;
        background-image: url('../../官网切图/首页/联系电话.png');
        background-repeat: no-repeat;
        background-size: auto 100%;
        .contact-phoneNumber{
          font-size: 21px;
          font-family: AppleSystemUIFont;
          font-weight: 400;
          color: #000000;
          line-height: 25px;
        }
        .contact-time{
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 25px;
        }
      }
    }
    .contact-form{
      float: right;
      margin-right: 147px;
      text-align: left;
      .contact-form-title{
        margin-top: 30px;
        font-size: 21px;
        font-family: AppleSystemUIFont;
        font-weight: 500;
        color: #232323;
        line-height: 30px
      }
      .form-input{
        // margin-top: 30px;
        width: 255px;
        height: 36px;
        border-radius: 6px;
        border: 1px solid #979797;
        overflow: hidden;
        .input-style{
          border: 0px;
          outline: none;
          width: 100%;
          height: 100%;
        }
      }
      .form-button{
        margin-top: 10px;
        margin-left: 66px;
        width: 120px;
        height: 42px;
        background: #EB3A32;
        border-radius: 6px;
        font-size: 16px;
        font-family: AppleSystemUIFont;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        text-align: center;
        border: none;
        cursor: pointer;
      }
    }
  }
}
}
@primary-color: #0099ff;@font-size-base: 14px;