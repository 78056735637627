.app {
    &-container {
       // width: 375px;
       width: 100%;
        &-header {
           // width: 375px;
            width: 100%;
            height: 261px;
            padding-left: 19px;
            position: relative;
            background-color: #f5f5f5;
            overflow: hidden;
            &-title {
                margin-top: 28px;
                font-size: 35px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #282828;
                line-height: 49px;
            }
            &-top {
                margin-top: 9px;
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #393939;
                line-height: 28px;
            }
            &-bottom {
                margin-top: 18px;
                width: 117px;
                height: 42px;
                background: #EB3A32;
                box-shadow: 0px 2px 9px 0px rgba(235,58,50,0.5);
                border-radius: 26px;
                backdrop-filter: blur(9px);
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                line-height: 42px;
                text-align: center;
                color:#E5E5E5;
                // .adm-button{
                //     color: #fff ;
                //     letter-spacing: 1.5px;
                //     --background-color:none;
        
                
            }
            &-mount {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 205px;
                height: 70px;
                background: url('../../../官网切图/首页/banner山.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }
        &-pic {
            width: 339px;
            height: 300px;
            margin: 27px auto 25px;
            // background: #EB3A32;
            background: url('../../../官网切图/首页/banner-mobile.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }
    &-body {
        margin:20px 0 20px 0;
        text-align: center;
        &-title {
            margin-top: 47px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #232323;
            line-height: 28px;
            text-align: center;
        }
        &-subtitle {
            margin-top: 5px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393939;
            line-height: 19px;  
            text-align: center;          
        }
        &-button {
            margin: 19px auto 0;
            width: 108px;
            height: 34px;
            border-radius: 5px;
            border: 1px solid #EB3A32;      
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #EB3A32;
            line-height: 32px;     
            text-align: center; 
        }
        &-step {
            //width: 360px;
            width:100%;
            height:100px;
            text-align: center;
            //height:0px;
            .fourSteps{
                //margin:18px 10px 20px 2px ;
                margin: 10px;
                height: 100px;
                background-image: url('../../../官网切图/小程序落地页/四步.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                // background-size: cover;
            }
        }
        &-scene {
            width: 350px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            &-icons {
                width: 20%;
                margin-top: 19px;
                &-icon {
                    margin: 0 auto;
                    width: 28px;
                    height: 28px;
                    background: url('../../../官网切图/小程序落地页/功能/店铺入驻.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
                &-tip {
                    height: 19px;
                    margin-top: 9px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #232323;
                    line-height: 19px;
                    text-align: center;                    
                }
            }
            :nth-child(2) {
                .app-body-scene-icons-icon {
                    background: url('../../../官网切图/小程序落地页/功能/商品.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            :nth-child(3) {
                .app-body-scene-icons-icon {
                    background: url('../../../官网切图/小程序落地页/功能/订单.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            :nth-child(4) {
                .app-body-scene-icons-icon {
                    background: url('../../../官网切图/小程序落地页/功能/资金.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            :nth-child(5) {
                .app-body-scene-icons-icon {
                    background: url('../../../官网切图/小程序落地页/功能/渠道.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            :nth-child(6) {
                .app-body-scene-icons-icon {
                    background: url('../../../官网切图/小程序落地页/功能/免押金.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            :nth-child(7) {
                .app-body-scene-icons-icon {
                    background: url('../../../官网切图/小程序落地页/功能/区块链.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            :nth-child(8) {
                .app-body-scene-icons-icon {
                    background: url('../../../官网切图/小程序落地页/功能/司法.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            :nth-child(9) {
                .app-body-scene-icons-icon {
                    background: url('../../../官网切图/小程序落地页/功能/租金.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            :nth-child(10) {
                .app-body-scene-icons-icon {
                    background: url('../../../官网切图/小程序落地页/功能/在线客服.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
        }
        &-program {
            &-secondary {
                margin-top: 14px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #232323;
                line-height: 21px;
                text-align: center;
            }
            &-describe {
                margin-top: 5px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #585858;
                line-height: 19px;   
                text-align: center;         
            }
        }
        &-models {
            margin-top: 19px;
           // width: 375px;
            width:100%;
            height: 143px;
            overflow-x: auto;
            &-inside {
                width: 459px;
                height: 143px;
                display: flex;
                justify-content: space-between;
                padding: 0 9px;
                &-mode {
                    position: relative;
                    width: 141px;
                    height: 143px;
                    background-image: url('../../../官网切图/小程序落地页/自营模式.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    &-mask {
                        position: absolute;
                        width: 141px;
                        height: 143px;
                        top: 0;
                        right: 0;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        &-title {
                            margin-top: 9px;
                            text-align: center;
                            font-size: 15px;
                            line-height: 21px;
                            background: rgba(0,0,0,0);
                        }
                        &-tip {
                            width: 122px;
                            margin: 9px auto 0;
                            font-size: 13px;
                            line-height: 19px;
                            background: rgba(0,0,0,0);
                            white-space:pre-wrap;
                        }
                    }
                }
                :nth-child(2).app-body-models-inside-mode{
                    background-image: url('../../../官网切图/小程序落地页/平台模式.png');
                }
                :nth-child(3).app-body-models-inside-mode{
                    background-image: url('../../../官网切图/小程序落地页/分销模式.png');
                }
            }
        }
    }
    .icon-box{
        //position: relative;
        display: flex;
        flex-wrap: nowrap;
        margin-top: 20px;
        width: 375px;
        height: 200px;
        display: inline-block;
        overflow: hidden;
        // .zhezhaoceng{
        //   top: 0;
        //   bottom: 0;
        //   left: 0;
        //   right: 0;
        //   width: 375px;
        //   height: 200px;
        //   display: inline-block;
        //   z-index: 100;
        //   opacity: 1;
        // }
        .store-icons{
          white-space: nowrap;
          overflow-x: scroll;
          font-size: 0;
          height:200px;
          padding-top: 2px;
            .store-icon{
            animation: infiniteScrool 15s linear infinite;
            display: inline-block;
            @keyframes infiniteScrool {
              0%{
                transform: translate3d(0,0,0);
              }
              100%{
                transform: translate3d(-100%,0,0);
              }
            }
            .icon{
              float: left;
              width: 159px;
              height: 196.5px;
              margin-right: 20px;
              background: #ffffff;
              position: relative;
              box-shadow: 0px 0px 9px 0px rgba(43,2,1,0.1);
              border-radius: 4px;
              overflow: hidden;
              .icon-bk{
                width: 159px;
                height: 76.5px;
                background-image: url('https://oss.huizustore.com/d640018a3a664d86ab80346019bcfc5a.png');
                background-repeat:no-repeat ;
                background-size: 100% auto;
                //overflow: hidden;
              }
              .icon-head{
                position: absolute;
                top: 49px;
                left: 9px;
                width: 56px;
                height: 56px;
                border-radius: 50%;
                background-image: url('https://oss.huizustore.com/d640018a3a664d86ab80346019bcfc5a.png');
                background-size: 100% auto;
              }
              .icon-title{
                position: absolute;
                top: 80px;
                left: 68px;
                font-size: 16.5px;
                font-family: AppleSystemUIFont;
                font-weight: 400;
                color: #585858;
                line-height: 33px;
              }
              .icon-content{
                //padding: 10px;
                position: absolute;
                //word-break: break-all !important;
                word-break: normal;
                top: 117px;
                left: 12px;
                font-size: 13.5px;
                font-family: AppleSystemUIFont;
                font-weight: 400;
                color: #393939;
                line-height: 1.6;
                overflow-wrap: break-word;
                letter-spacing: 2px;
                text-align: left;
                white-space:pre-wrap !important;
              }
            }
           }
            :nth-child(2){
            .icon-bk{
                background-image: url('https://oss.huizustore.com/82038a891de743d2adc59c1947835b53.png') !important;
              }
            .icon-head{
                background-image: url('https://oss.huizustore.com/ead46aeed8774ba2b12e02708194940d.png') !important;
            }
            }
            :nth-child(3){
                .icon-bk{
                    background-image: url('https://oss.huizustore.com/77dcacc920754e25b80295179f301b66.jpeg') !important;
                }
                .icon-head{
                    background-image: url('https://oss.huizustore.com/65a6a2b017e74c46a63ea1df7f232998.jpeg') !important;
                }
            }
            :nth-child(4){
                .icon-bk{
                    background-image: url('https://oss.huizustore.com/e10027f933e14650a5afb0cb87691383.png') !important;
                }
                .icon-head{
                    background-image: url('https://oss.huizustore.com/7f6ff13537dc45b5a3c71642096b68db.png') !important;
                }
            }
            :nth-child(5){
                .icon-bk{
                    background-image: url('https://oss.huizustore.com/6bf80e74bbba4010af76856ca4a690cd.png') !important;
                }
                .icon-head{
                    background-image: url('https://oss.huizustore.com/3981dd6ae1344ac2a9452f546f0844da.png') !important;
                }
            }
        }
        .store-icons::-webkit-scrollbar {
          /*滚动条整体样式*/
          width : 0px!important;  /*高宽分别对应横竖滚动条的尺寸*/
          height: 0px!important;
          }
      }
    &-service{
        height:150px;
        //width:375px;
        width:100%;
        background-image: url('../../../官网切图/关于我门/banner-mobile.png');
        background-repeat:no-repeat;
        background-size: cover;
        overflow: hidden;
        &-title{
            text-align: center;
            padding-top: 18px;
            letter-spacing: 1.5px;
            font-size: 20px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 60px;
        }
        &-button{
            text-align: center;
            .adm-button{
                color: #EB3A32 ;
                letter-spacing: 1.5px;
            
            }
        }
    }

    .modalInquiry{
        border:2px solid white;
        .pic-box{
            border-radius: 16px;
            text-align: center;
            .pic{
              margin:20px auto;
              width: 230px;
              height: 230px;
              background-image: url('../../../官网切图/首页/客服经理.png');
              background-size: 100% 100%;
            }
            .tip{
              text-align: center;
              font-size: 21px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              line-height: 20px;
            }
        }
        .content{
            margin-top: 10px;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        }
    }
   
}
@primary-color: #0099ff;@font-size-base: 14px;