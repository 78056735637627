.container-store{
  min-width: 1440px;
  margin: 0;
  height: 600px;
  background: #f8f8f8;
  // z-index: -1;
  position: relative;
  .header{
    position: relative;   
    width: 1440px;
    height: 600px;
    overflow: hidden;
    margin: -60px auto 0;
    background: url('../../../官网切图/首页/banner 主图.png');
    background-size: 100% 100%;
    .top-content-top{
      margin-left: 195px;
      margin-top: 195px;
      // width: 417px;
      font-size: 36px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      line-height: 44px;
    }
    .top-content-center{
      margin-left: 195px;
      margin-top: 18px;
      // width: 336px;
      height: 19px;
      font-size: 27px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      line-height: 19px
    }
    .top-content-bottom{
      margin-left: 195px;
      margin-top: 30px;
      width: 210px;
      height: 66px;
      background: #EB3A32;
      box-shadow: 0px 2px 4px 0px #F2AAA8;
      border-radius: 33px;
      border: 3px solid #EB3A32;
      // filter: blur(9px);
      text-align: center;
      line-height: 60px;
      font-size: 36px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
      a{
        color: #FFFFFF;
      }
      &:hover{
        border: 3px solid rgba(255, 255, 255, 0.3);
      }
    }
    .pic-left{
      position: absolute;
      width: 282px;
      height: 96px;
      background: url('../../../官网切图/首页/banner山.png');
      background-size: 100% 100%;
      left: 48px;
      bottom: 30px;
    }
    .pic-right{
      position: absolute;
      width: 460px;
      height: 408px;
      top: 98px;
      bottom: 94px;
      right: 130px;
    }
  }
  .modalReport{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      width: 600px;
      height: 500px;
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #ffffff;
      }
  } 
  .ant-modal-header{
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
    border-radius: 8px;
  }
  .ant-modal-title {
    font-size: 27px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 30px;
  }
  .ant-modal-body{
    // padding-top: 12px;
    .title{
      text-align: center;
      font-size: 27px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      margin-bottom: 18px;
      // line-height: 40.5px;
    }
    .icon{
      text-align: center;
      margin: 0 auto;
      width: 300px;
      height: 330px;
      background: #FFFFFF;
      border-radius: 16px;
      border: 2px solid #FFFFFF;
      padding-top: 30px;
      .icon-pic{
        margin: 0 auto;
        width: 240px;
        height: 240px;
        background-image: url('../../../官网切图/二维码/风控报告.png');
        background-size: 100% 100%;
      }
      .icon-title{
        margin-top: 10px;
        font-size: 21px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #3B6FEF;
        line-height: 31.5px;
      }
    }
    .icon-tip{
      margin-top: 14px;
      font-size: 21px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 31.5px;
      text-align: center;
    }
  }
  }
  .modalRevice{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      margin: 0 auto;
      width: 930px;
      height: 390px;
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #ffffff;
      }
      .background{
        .title{
          font-size: 27px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 40.5px;
          text-align: center;
        }
        .icons{
          margin: 0 auto;
          width: 876px;
          height: 270px;
          margin-left: 26px;
          margin-top: 24px;
          .icon-left{
            float: left;
            width: 240px;
            height: 270px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .left-pic{
              width: 192px;
              height: 192px;
              margin-top: 18px;
              margin-left: 24px;
              background-image: url("../../../官网切图/首页/二维码.png");
              background-size: 100% 100%;
            }
            .left-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #3B6FEF;
              line-height: 31.5px;
            }
          }
          .icon-center{
            margin-left: 54px;
            float: left;
            width: 240px;
            height: 270px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .center-pic{
              width: 192px;
              height: 192px;
              margin-top: 18px;
              margin-left: 24px;
              background-image: url("../../../官网切图/二维码/IOS.png");
              background-size: 100% 100%;
            }
            .center-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #3B6FEF;
              line-height: 31.5px;
              .tip-small{
                margin-top: -10px;
                font-size: 10px;
              }
            }
          }
          .icon-right{
            margin-left: 54px;
            float: left;
            width: 240px;
            height: 270px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 2px solid #FFFFFF;
            text-align: center;
            .right-pic{
              width: 192px;
              height: 192px;
              margin-top: 18px;
              margin-left: 24px;
              background-image: url("../../../官网切图/二维码/安卓.png");
              background-size: 100% 100%;
            }
            .right-tip{
              margin-top: 12px;
              font-size: 21px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #3B6FEF;
              line-height: 31.5px;
            }
          }
        }
      }
    } 
  }
  .modalLease{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      margin: 0 auto;
      width: 660px;
      height: 770px;
      box-shadow: 0px 0px 4px 4px rgba(57, 59, 65, 0.1);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .ant-modal-close{
        color: #ffffff;
      }
      .modalContainer{
        .title{
          font-size: 24px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 36px;
          text-align: center;
        }
        .title-top{
          margin-left: 18px;
          margin-top: 30px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 27px;
        }
        .icons{
          margin-left: 18px;
          .iconTop{
            height: 90px;
            margin-top: 12px;
            .left{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              border: 2px solid #ffffff;
              cursor: pointer;
              .left-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_bijiben @2x.png");
                background-size: 100% 100%;
              }
              .left-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
            .center{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              border: 2px solid #ffffff;
              margin-left: 18px;
              .center-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_taishiji @2x.png");
                background-size: 100% 100%;
              }
              .center-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
            .right{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #ffffff;
              .right-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_dayinji @2x.png");
                background-size: 100% 100%;
              }
              .right-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
          }
          .iconBottom{
            height: 90px;
            margin-top: 18px;
            .left{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              border: 2px solid #ffffff;
              .left-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_shouji @2x.png");
                background-size: 100% 100%;
              }
              .left-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
            .center{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #ffffff;
              .center-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_touyingyi @2x.png");
                background-size: 100% 100%;
              }
              .center-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
            .right{
              float: left;
              width: 180px;
              height: 90px;
              background: #FFFFFF;
              border-radius: 10px;
              margin-left: 18px;
              border: 2px solid #ffffff;
              .right-pic{
                float: left;
                margin-top: 15px;
                margin-left: 18px;
                width: 60px;
                height: 60px;
                // border: 1px dashed #000000;
                background-image: url("../../../官网切图/企业租赁/img_其他服务@2x.png");
                background-size: 100% 100%;
              }
              .right-font{
                float: left;
                margin-top: 36px;
                margin-left: 18px;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
              }
            }
          }
        }
        .title-center{
          margin-left: 18px;
          margin-top: 30px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 27px;
        }
        .textArea{
          margin-left: 18px;
          margin-top: 12px;
          width: 576px;
          height: 198px;
          background: rgba(216, 216, 216, 0.55);
          border-radius: 8px;
          border: 2px solid #FFFFFF;
          overflow: hidden;
          .TextArea{
            .ant-input{
              min-height: 170px;
              resize: none;
              &::-webkit-input-placeholder{
                font-size: 18px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 27px;
                letter-spacing: 2px;
              }
            }
          }
        }
        .form{
          margin-left: 18px;
          margin-top: 30px;
          .title-bottom{
            float: left;
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 36px;
          }
          .input{
            float: left;
            width: 300px;
            height: 36px;
            background: rgba(216, 216, 216, 0.55);
            border-radius: 8px;
            border: 2px solid #FFFFFF;
            overflow: hidden;
            .input-child{
              border: 0px;
              outline: none;
              width: 100%;
              height: 100%;
              background: rgba(216, 216, 216, 0.55);
              &::-webkit-input-placeholder{
                font-size: 16.5px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 33px;
              }
            }
          }
        }
        .button{
          float: left;
          margin-top: 6px;
          margin-left: 150px;
          width: 330px;
          height: 60px;
          text-align: center;
          background: #3B6FEF;
          border-radius: 48px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 50px;
        }
      }
    }
  }
  .modalInquiry{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      width: 600px;
      height: 498px;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .title{
        text-align: center;
        font-size: 27px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 54px;
      }
      .pic-box{
        margin-top: 8px;
        margin-left: 126px;
        width: 300px;
        height: 330px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 2px solid #FFFFFF;
        .pic{
          margin-top: 27px;
          margin-left: 27px;
          width: 246px;
          height: 246px;
          background-image: url('../../../官网切图/二维码/微信客服.png');
          background-size: 100% 100%;
        }
        .tip{
          text-align: center;
          font-size: 21px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #3B6FEF;
          line-height: 42px;
        }
      }
      .content{
        margin-top: 18px;
        text-align: center;
        font-size: 21px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 28px;
      }
    }
  }
}
  



@primary-color: #0099ff;@font-size-base: 14px;