.storeContent{
  margin-top: 90px;
  .process{
    margin: 0 auto;
    width: 1056px;
    height: 523px;
    background-image: url('../../../官网切图/租赁店铺页面/编组 20备份.png');
    background-size: 100% 100%;
  }
  .drainage{
    margin: 0 auto;
    margin-top: 90px;
    width: 1050px;
    height: 352px;
    .drainage-icon{
      // margin-top: 18px;
      float: left;
      width: 525px;
      height: 352px;
      background-image: url('../../../官网切图/租赁店铺页面/引流插画.jpg');
      background-size: 100% 100%;
      // border: 1px dashed #000000;
    }
    .drainage-content{
      float: right;
      margin-left: 42px;
      // margin-top: 18px;
      .content-title{
        margin-top: 95px;
        font-size: 27px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
        line-height: 38px;
      }
      .content-content{
        margin-top: 18px;
        font-size: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #585858;
        line-height: 30px;
      }
      .content-button{
        &:hover{
          background: url('../../../官网切图/首页/试用按钮.png');
          background-size: 100% 100%;
          color: #ffffff;
          overflow: hidden;
        }
        // display: flex;
        // align-items: center;
        // justify-content: center;
        margin-top: 18px;
        width: 120px;
        height: 42px;
        text-align: center;
        border: 2px solid #EB3A32;
        border-radius: 8px;
        font-size: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #EB3A32;
        line-height: 38px;
        cursor: pointer;
      }
    }
  }
  .openShop{
    margin: 0 auto;
    width: 1050px;
    height: 430px;
    .openShop-content{
      padding-top: 92px;
      float: left;
      .openShop-title{
        margin-top: 61px;
        margin-bottom: 5px;
        font-size: 27px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
        line-height: 38px;
      }
      .openShop-contents{
        margin-top: 12px;
        font-size: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #585858;
        line-height: 30px;
      }
      .content-button{
        &:hover{
          background: url('../../../官网切图/首页/试用按钮.png');
          background-size: 100% 100%;
          color: #ffffff;
          overflow: hidden;
        }
        margin-top: 18px;
        width: 120px;
        height: 42px;
        text-align: center;
        border: 2px solid #EB3A32;
        border-radius: 8px;
        font-size: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #EB3A32;
        line-height: 38px;
        cursor: pointer;
      }
    }
    .openShop-icon{
      float: right;
      margin-top: 92px;
      // margin-left: 33px;
      width: 500px;
      height: 338px;
      background-image: url('../../../官网切图/租赁店铺页面/0成本开店插画.png');
      background-size: 100% 100%;
      // border: 1px dashed #000000;
    }
  }
  .steps{
    margin: 0 auto;
    width: 900px;
    .fourSteps{
      margin-top: 90px;
      width: 900px;
      height: 245px;
      background-image: url('../../../官网切图/小程序落地页/四步.png');
      background-size: 100% 100%;
    }
  }
  .otherStore{
    margin: 0 auto;
    width: 1050px;
    text-align: center;
    .otherStore-title{
      margin-top: 90px;
      font-size: 27px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #232323;
      line-height: 38px;
    }
    .otherStore-tip{
      margin-top: 12px;
      font-size: 14px;
      font-family: AppleSystemUIFont;
      font-weight: 400;
      color: #232323;
      line-height: 16px;
    }
    .icon-box{
      position: relative;
      margin-top: 47px;
      width: 1050px;
      height: 196.5px;
      height: 200px;
      display: inline-block;
      overflow: hidden;
      .zhezhaoceng{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 1065px;
        height: 196.5px;
        height: 200px;
        display: inline-block;
        overflow: hidden;
        z-index: 100;
        opacity: 1;
      }
      .store-icons{
        white-space: nowrap;
        overflow-x: scroll;
        font-size: 0;
        height: 200px;
        padding-top: 2px;
        .store-icon{
          animation: infiniteScrool 15s linear infinite;
          display: inline-block;
          @keyframes infiniteScrool {
            0%{
              transform: translate3d(0,0,0);
            }
            100%{
              transform: translate3d(-100%,0,0);
            }
          }
          .icon{
            float: left;
            width: 159px;
            height: 194.5px;
            margin-right: 50px;
            background: #ffffff;
            position: relative;
            box-shadow: 0px 0px 9px 0px rgba(43,2,1,0.1);
            border-radius: 4px;
            overflow: hidden;
            .icon1-bk{
              width: 159px;
              height: 76.5px;
              background-image: url('https://oss.huizustore.com/d640018a3a664d86ab80346019bcfc5a.png');
              background-size: 100% auto;
              overflow: hidden;
            }
            .icon1-head{
              position: absolute;
              top: 49px;
              left: 9px;
              width: 56px;
              height: 56px;
              border-radius: 50%;
              background-image: url('https://oss.huizustore.com/d640018a3a664d86ab80346019bcfc5a.png');
              background-size: 100% auto;
            }
            .icon1-title{
              position: absolute;
              top: 80px;
              left: 68px;
              font-size: 16.5px;
              font-family: AppleSystemUIFont;
              font-weight: 400;
              color: #585858;
              line-height: 33px;
            }
            .icon1-content{
              position: absolute;
              top: 117px;
              left: 12px;
              font-size: 13.5px;
              font-family: AppleSystemUIFont;
              font-weight: 400;
              color: #393939;
              line-height: 19px;
              letter-spacing: 2px;
              text-align: left;
            }
            .icon2-bk{
              width: 159px;
              height: 76.5px;
              background-image: url('https://oss.huizustore.com/82038a891de743d2adc59c1947835b53.png');
              background-size: 100% auto;
              overflow: hidden;
            }
            .icon2-head{
              position: absolute;
              top: 49px;
              left: 9px;
              width: 56px;
              height: 56px;
              border-radius: 50%;
              background-image: url('https://oss.huizustore.com/ead46aeed8774ba2b12e02708194940d.png');
              background-size: 100% auto;
            }
            .icon3-bk{
              width: 159px;
              height: 76.5px;
              background-image: url('https://oss.huizustore.com/77dcacc920754e25b80295179f301b66.jpeg');
              background-size: 100% auto;
              overflow: hidden;
            }
            .icon3-head{
              position: absolute;
              top: 49px;
              left: 9px;
              width: 56px;
              height: 56px;
              border-radius: 50%;
              background-image: url('https://oss.huizustore.com/65a6a2b017e74c46a63ea1df7f232998.jpeg');
              background-size: 100% auto;
            }
            .icon4-bk{
              width: 159px;
              height: 76.5px;
              background-image: url('https://oss.huizustore.com/e10027f933e14650a5afb0cb87691383.png');
              background-size: 100% auto;
              overflow: hidden;
            }
            .icon4-head{
              position: absolute;
              top: 49px;
              left: 9px;
              width: 56px;
              height: 56px;
              border-radius: 50%;
              background-image: url('https://oss.huizustore.com/7f6ff13537dc45b5a3c71642096b68db.png');
              background-size: 100% auto;
            }
            .icon5-bk{
              width: 159px;
              height: 76.5px;
              background-image: url('https://oss.huizustore.com/6bf80e74bbba4010af76856ca4a690cd.png');
              background-size: 100% auto;
              overflow: hidden;
            }
            .icon5-head{
              position: absolute;
              top: 49px;
              left: 9px;
              width: 56px;
              height: 56px;
              border-radius: 50%;
              background-image: url('https://oss.huizustore.com/3981dd6ae1344ac2a9452f546f0844da.png');
              background-size: 100% auto;
            }
          }
        }
      }
      .store-icons::-webkit-scrollbar {
        /*滚动条整体样式*/
        width : 0px!important;  /*高宽分别对应横竖滚动条的尺寸*/
        height: 0px!important;
        }
    }
  }
  .service{
    margin-top: 150px;
    height: 368.25px;
    background: #666666;
    margin-bottom: -150px;
    overflow: hidden;
    .service-title{
      margin-top: 30px;
      text-align: center;
      height: 53px;
      font-size: 38px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 53px;
    }
    .hrs{
      margin: 0;
      margin-top: 14px;
      height: 1px;
      background: rgba(255, 255, 255, 0.4);
    }
    .service-action{
      width: 1440px;
      margin: 0 auto;
      .code-icon{
        float: left;
        margin-top:61px ;
        margin-left: 401px;
        width: 156px;
        height: 156px;
        border: 4px solid #EF983B;
        border-radius: 10px;
        background-image: url('../../../官网切图/二维码/微信客服.png');
        background-size: 100% 100%;
      }
      .service-content{
        float: left;
        margin-left: 30px;
        .content-first{
          margin-top: 61px;
          font-size: 27px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 38px;
        }
        .content-second{
          margin-top: 20px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 25px;
        }
        .content-third{
          margin-top: 32px;
          width: 108px;
          height: 42px;
          background: #EF983B;
          border-radius: 6px;
          line-height: 42px;
          text-align: center;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          cursor: pointer;
          &:hover{
            background: #FFB15D;
          }
        }
      }
      .service-icon{
        float: left;
        margin-top: 12px;
        width: 349px;
        height: 231px;
        // border: 1px dashed #f2f2f2;
        background-image: url('../../../官网切图/租赁店铺页面/客服.png');
        background-size: 100% 100%;
      }
    }
  }
  .modalInquiry{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      width: 600px;
      height: 498px;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .title{
        text-align: center;
        font-size: 27px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 54px;
      }
      .pic-box{
        margin-top: 8px;
        margin-left: 126px;
        width: 300px;
        height: 330px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 2px solid #FFFFFF;
        .pic{
          margin-top: 27px;
          margin-left: 27px;
          width: 246px;
          height: 246px;
          background-image: url('../../../官网切图/二维码/微信客服.png');
          background-size: 100% 100%;
        }
        .tip{
          text-align: center;
          font-size: 21px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #3B6FEF;
          line-height: 42px;
        }
      }
      .content{
        margin-top: 18px;
        text-align: center;
        font-size: 21px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 28px;
      }
    }
  }
  .modalAppointment{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      width: 570px;
      height: 417px;
      // background: #FFFFFF;
      border-radius: 8px;
      .title{
        text-align: center;
        font-size: 27px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #232323;
        line-height: 54px;
      }
      .form-div{
        width: 360px;
        margin-left: 88.5px;
        margin-top: 30px;
        font-size: 18px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #232323;
        height: 36px;
        // line-height: 36px;
        .span{
          float: left;
          margin-top: 6px;
          display: block;
          height: 25px;
          line-height: 25px;
        }
        .inputs{
          float: left;
          display: block;
          width: 255px;
          height: 36px;
          border-radius: 6px;
          border: 1px solid #979797;
          overflow: hidden;
          .input{
            outline: none;
            border: 0px;
            width: 100%;
            height: 100%;
          }
        }
      }
      .buttonBox{
        .button{
          margin-left: 201px;
          margin-top: 24px;
          width: 120px;
          height: 42px;
          background: #EB3A32;
          border-radius: 8px;
          line-height: 34px;
          font-size: 18px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          border: none;
          cursor: pointer;
        }
      }

    }
  }
}
@primary-color: #0099ff;@font-size-base: 14px;