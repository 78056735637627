.mobileContent {
    width: 100%;
    .fixBox{
        z-index: 10;
        top: 100px;
        right: 12px;
        // width: 78px;
        height: 300px;
        position: fixed;
        .box-right{
          .top{
            width: 78px;
            height: 93px;
            background-image: url('../../../官网切图/首页/客服悬浮窗/客服.png');
            background-position: 137px 152px;
            background-size: 252%;
          }
          .box{
            backdrop-filter: blur(10px);
            margin-top: -15px;
            margin-left: 6px;
            width: 66px;
            height: 21px;
            background: rgba(0, 0, 0, 0.7);
            border-radius: 3px;
            .tip{
              text-align: center;
              line-height: 21px;
              color: #ffffff;
              font-size: 12px;
            }
          }
          .lease{
            margin-top: 6px;
            width: 78px;
            height: 78px;
            background: rgba(0, 0, 0, 0.7);
            border-radius: 6px;
            padding-top: 15px;
            .pic{
              margin-left: 24px;
              width: 30px;
              height: 30px;
              background-image: url('../../../官网切图/首页/客服悬浮窗/编组 18.png');
              background-size: 100% 100%;
            }
            .font{
              text-align: center;
              margin-top: 6px;
              font-size: 13.5px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 21px;
            }
            .box-lease{
              backdrop-filter: blur(10px);
              display: none;
              position: fixed;
              right: 102px;
              top: 100px;
              width: 260px;
              height: 411px;
              background: rgba(255, 255, 255, 0.4);
              box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08), 0px 0px 12px 0px rgba(43,2,1,0.12);
              border-radius: 8px;
              border: 1px solid #FFFFFF;
              backdrop-filter: blur(10px);
              .title{
                //margin-top: 48px;
               // margin-left: 120px;
                margin: 40px auto 20px; 
                text-align: center;
                font-size: 27px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #232323;
                line-height: 38px;
                text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
              }
              .pic{
                //margin-top: 18px;
                //margin-left: 55px;
                width: 200px;
                height: 200px;
                background-image: url('../../../官网切图/首页/消费者业务二维码.png');
                background-size: 100% 100%;
              }
              .tip{
                margin-top: 18px;
                height: 30px;
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #393939;
                line-height: 30px;
                text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
                text-align: center;
              }
            }
            cursor: pointer;
            &:hover{
              background: #EB3A32;
              .box-lease{
                display: block;
              }
            }
          }
          .switch{
            margin-top: 18px;
            margin-bottom: -156px;
            width: 78px;
            height: 156px;
            background: rgba(0, 0, 0, 0.7);
            box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08);
            border-radius: 5px;
            backdrop-filter: blur(6px);
          }
          .wechat{
            position: relative;
            width: 78px;
            height: 78px;
            // background: rgba(0, 0, 0, 0.7);
            border-radius: 5px;
            padding-top: 15px;
            z-index: 1;
            .pic{
              margin-left: 24px;
              width: 30px;
              height: 30px;
              background-image: url('../../../官网切图/首页/客服悬浮窗/微信 (2).png');
              background-size: 100% 100%;
            }
            .font{
              text-align: center;
              margin-top: 6px;
              font-size: 13.5px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 21px;
            }
            cursor: pointer;
            &:hover{
              background: #EB3A32;
              .box-wechat{
                display: block;
              }
            }
            .box-wechat{
              backdrop-filter: blur(10px);
              display: none;
              position: fixed;
              right: 102px;
              top: 100px;
              width: 260px;
              height: 405px;
              background: rgba(255, 255, 255, 0.79);
              box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08), 0px 0px 12px 0px rgba(43,2,1,0.12);
              border-radius: 8px;
              border: 1px solid #FFFFFF;
              backdrop-filter: blur(10px);
              .title{
               // margin-top: 48px;
                //margin-left: 114px;
                margin: 40px auto 0; 
                text-align: center;
                height: 38px;
                font-size: 27px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #232323;
                line-height: 38px;
                text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
              }
              .tip{
                text-align: center;
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #393939;
                line-height: 17px;
                text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
              }
              .pic{
                //margin-top: 18px;
                margin-left: 35px;
                margin-top: 20px;
                width: 190px;
                height: 190px;
                background-image: url('../../../官网切图/二维码/微信客服.png');
                background-size: 100% 100%;
              }
              .content{
                margin-top: 18px;
                text-align: center;
                height: 30px;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #393939;
                line-height: 30px;
                text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
              }
            }
          }
          .phone{
            position: relative;
            z-index: 1;
            // margin-top: 6px;
            width: 78px;
            height: 78px;
            // background: rgba(0, 0, 0, 0.7);
            border-radius: 5px;
            padding-top: 15px;
            .pic{
              margin-left: 24px;
              width: 30px;
              height: 30px;
              background-image: url('../../../官网切图/首页/客服悬浮窗/编组 11.png');
              background-size: 100% 100%;
            }
            .font{
              text-align: center;
              margin-top: 6px;
              font-size: 13.5px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 21px;
            }
            &:hover{
              background: #EB3A32;
              .box-phone{
                display: block;
              }
            }
            cursor: pointer;
            .box-phone{
              backdrop-filter: blur(10px);
              display: none;
              position: fixed;
              right: 102px;
              top: 382px;
              width: 260px;
              height: 78px;
              background: rgba(255, 255, 255, 0.79);
              box-shadow: 0px 6px 36px 0px rgba(43,2,1,0.08), 0px 0px 12px 0px rgba(43,2,1,0.12);
              border-radius: 8px;
              border: 1px solid #FFFFFF;
              backdrop-filter: blur(10px);
              .top1{
                font-size: 13.5px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #232323;
                line-height: 48px;
                padding-top: 30px;
                .big{
                  font-size: 24px;
                  //margin-left: 30px;
                  // margin-top: 39px;
                }
              }
              .bottom{
                background-color: transparent;
                margin: 20px auto 29px 15px;
                font-size: 18px;
                font-family: AppleSystemUIFont;
                color: #232323;
                line-height: 21px;
                text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
                .small{
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #232323;
                  line-height: 19px;
                  text-shadow: 0px 6px 36px rgba(43, 2, 1, 0.08);
                }
              }
            }
          }
        }
      
      
      
      }
    ul{
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
    &-container {
       // width: 375px;
       width:100%;
        &-header {
           // width: 375px;
            width:100%;
            height: 261px;
            padding-left: 19px;
            position: relative;
            background-color: #f5f5f5;
            overflow: hidden;
            &-title {
                margin-top: 18px;
                font-size: 35px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #282828;
                line-height: 49px;
            }
            &-top {
                margin-top: 9px;
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #393939;
                line-height: 28px;
            }
            &-center {
                margin-top: 5px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #393939;
                line-height: 19px;
            }
            &-bottom {
                margin-top: 18px;
                width: 117px;
                height: 42px;
                background: #EB3A32;
                box-shadow: 0 2px 9px 0 rgba(235,58,50,0.5);
                border-radius: 26px;
                backdrop-filter: blur(9px);
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                line-height: 42px;
                text-align: center;
                a {
                    color: #E5E5E5;
                }
            }
            &-mount {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 205px;
                height: 70px;
                background: url('../../../官网切图/首页/banner山.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }
        &-pic {
            width: 339px;
            height: 300px;
            margin: 27px 12px 0 25px;
            // background: #EB3A32;
            background: url('../../../官网切图/首页/banner-mobile.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }
    &-body {
       // width: 375px;
        &-data {
            margin: 42px auto 0;
           // width: 338px;
            height: 353px;
            background: #FFFFFF;
            box-shadow: 0px 2px 14px 0px rgba(54,2,0,0.08);
            border-radius: 14px;
            overflow: hidden;
            &-time {
                margin: 18px auto 0;
                height: 28px;
                font-size: 13px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #282828;
                line-height: 19px;
                text-align: center;
                &-upTo {
                    display: inline-block;
                    font-size: 20px;
                    line-height: 28px;
                }
            }
            &-datas {
                margin: 14px 20px 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                &-stati {
                    width: 128px;
                    height: 128px;
                    margin-bottom: 19px;
                    border: 9px solid rgba(235, 58, 50, 0.08);
                    border-radius: 50%;
                    &-span {
                        display: block;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        text-align: center;
                        &-font {
                            display: block;
                            margin-top: 32px;
                            height: 33px;
                            font-size: 23px;
                            color: #282828;
                            line-height: 33px;
                        }
                        &-num {
                            margin-top: -5px;
                            display: block;
                            height: 19px;
                            font-size: 13px;
                            color: #585858;
                            line-height: 19px;
                        }
                    } 
                }
                &-line {
                    width: 2px;
                    height: 70px;
                    background: linear-gradient(
                    180deg, 
                    rgba(255, 255, 255, 0) 0%, 
                    rgba(235, 58, 50, 0.2) 51%, 
                    rgba(235, 58, 50, 0) 100%
                    );
                    margin-top: 28px;
                }
            }
        }
        &-product {
            &-title {
                margin-top: 47px;
                height: 28px;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #232323;
                line-height: 28px;
                text-align: center;
            }
            &-hr {
                width: 141px;
                height: 1px;
                background: #EF983B;
                margin: 5px auto 0;
                border: none;
            }
            &-subtitle {
                width: 232px;
                height: 38px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #393939;
                line-height: 19px;
                margin: 9px auto 0;
                text-align: center;
            }
            &-icons {
                width: 338px;
                margin: 38px auto;
                display: flex;
                justify-content: space-around;
                &-box {
                    width: 33.3%;
                    height: 122px;
                    text-align: center;
                    white-space:nowrap;
                    &-icon {
                        width: 75px;
                        height: 75px;
                        margin: 19px auto 0;
                        background: url('../../../官网切图/首页/店铺 icon.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                    }
                    &-name {
                        display: inline-block;
                        height: 19px;
                        font-size: 13px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #393939;
                        line-height: 19px;
                        margin-top: 9px;
                        //text-align: center;
                        //white-space:nowrap;
                        
                        //transform: translateX(-50%);
                        //margin-left: 50%;
                    }
                    &-name-active {
                        display: inline-block;
                        height: 21px;
                        font-size: 13px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #282828;
                        line-height: 19px;
                        margin-top: 9px;
                        transform: translateX(-50%);
                        margin-left: 50%;
                        padding-bottom: 21px;
                        border-bottom: 2px solid #EB3A32;
                    }
                }
                :nth-child(2){
                    .mobileContent-body-product-icons-box-icon {
                        background: url('../../../官网切图/首页/小程序 icon.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                    }
                }
                :nth-child(3){
                    .mobileContent-body-product-icons-box-icon {
                        background: url('../../../官网切图/首页/PC icon备份.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                    }
                }
            }
            &-card {
                width: 338px;
                height: 264px;
                background: #FFFFFF;
                box-shadow: 0px 2px 14px 0px rgba(54,2,0,0.08);
                border-radius: 12px;
                margin: 14px auto 47px;
                padding: 19px;
                &-title {
                    display: block;
                    height: 21px;
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #232323;
                    line-height: 21px;
                    margin-top: 2px;
                }
                &-contents {
                    width: 300px;
                    height: 141px;
                    li {
                        margin-top: 9px;
                        display: flex;
                    }
                    &-dot {
                        display: inline-block;
                        width: 9px;
                        height: 9px;
                        background: #EF983B;
                        box-shadow: inset -1px -1px 4px 0px rgba(209,108,0,1);
                        margin: 5px 9px 0 0;
                        border-radius: 50%;
                    }
                    &-words {
                        display: inline-block;
                        width: 281px;
                        font-size: 13px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #585858;
                        line-height: 19px;
                    }
                }
                &-button {
                    width: 244px;
                    margin: 19px auto;
                    display: flex;
                    justify-content: space-between;
                    &-left {
                        width: 108px;
                        height: 34px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        line-height: 34px;
                        text-align: center;
                        background-image: url(../../../官网切图/首页/试用按钮.png);
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        a {
                            color: #FFFFFF;
                        }
                    }
                    &-right {
                        width: 108px;
                        height: 34px;
                        border-radius: 5px;
                        border: 1px solid #EB3A32;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #EB3A32;
                        line-height: 32px;
                        text-align: center;
                    }
                }
            }
        }
        &-guarantee {
           // width: 375px;
            width:100%;
            height: 436px;
            background: #F6F6F6;
            overflow: hidden;
            &-title {
                height: 28px;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #232323;
                line-height: 28px;
                margin-top: 47px;
                text-align: center;
            }
            &-hr {
                width: 141px;
                height: 1px;
                background: #EF983B;
                margin: 5px auto 0;
                border-style: none;
            }
            &-subtitle {
                height: 19px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #393939;
                line-height: 19px;
                margin-top: 9px;
                text-align: center;
            }
            &-content {
                margin-top: 19px;
                height: 261px;
                //width: 375px;
                width:100%;
                overflow-x: auto;
                .store-icons::-webkit-scrollbar {
                    /*滚动条整体样式*/
                    width : 0px!important;  /*高宽分别对应横竖滚动条的尺寸*/
                    height: 0px!important;
                }
                &-scroll {
                    width: 654px;
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    padding: 0 9px;
                    &-card {
                        width: 206px;
                        height: 261px;
                        background: #FFFFFF;
                        border-radius: 9px;
                        overflow: hidden;
                        &-inside {
                            &-icon {
                                width: 56px;
                                height: 56px;
                                margin: 19px auto 0;
                                background-image: url(../../../官网切图/首页/免押金.png);
                                background-size: 100% 100%;
                                background-repeat: no-repeat;
                            }
                            &-title {
                                display: block;
                                height: 25px;
                                margin-top: 9px;
                                font-size: 18px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #232323;
                                line-height: 25px;
                                text-align: center;
                            }
                            &-tip {
                                // display: inline-block;
                                display: block;
                                height: 56px;
                                width: 206px;
                                margin-top: 9px;
                                font-size: 13px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #585858;
                                line-height: 19px;
                                text-align: center;                              
                            }
                            &-footer {
                                width: 206px;
                                height: 68px;
                                margin-top: 19px;
                                background-image: url(../../../官网切图/首页/保障底部装饰.png);
                                background-size: 100% 100%;
                                background-repeat: no-repeat;    
                                font-size: 15px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #282828;
                                line-height: 68px;
                                text-align: center;                                                            
                            }
                        }
                    }
                    :nth-child(2) {
                        .mobileContent-body-guarantee-content-scroll-card-inside-icon {
                            background-image: url(../../../官网切图/首页/仲裁.png);
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                        }
                    }
                    :nth-child(3) {
                        .mobileContent-body-guarantee-content-scroll-card-inside-icon {
                            background-image: url(../../../官网切图/首页/区块链.png);
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
        }
        &-business {
            width: 337px;
            margin: 0 auto;
            &-title {
                height: 28px;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #232323;
                line-height: 28px;
                margin-top: 47px;             
            }
            &-subtitle {
                height: 19px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #585858;
                line-height: 19px;
                margin-top: 5px;
            }
            &-pic {
                width: 337px;
                height: 258px;
                margin-top: 28px;
                background-image: url(../../../官网切图/首页/全行业全场景.png);
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }
        &-consumption {
            &-title {
                height: 28px;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #232323;
                line-height: 28px;
                margin-top: 47px;
                text-align: center;
            }
            &-hr {
                width: 141px;
                height: 1px;
                background: #EF983B;
                margin: 5px auto 0;
                border-style: none;                
            }
            &-subtitle {
                height: 19px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #393939;
                line-height: 19px;
                margin-top: 9px;
                text-align: center;
            }
            &-content {
                width: 338px;
                height: 919px;
                margin: 19px auto 0;
                background: skyblue;
                background-image: url("../../../官网切图/首页/编组 114.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            &-button {
                width: 108px;
                height: 34px;
                background: linear-gradient(90deg, #EB3A32 0%, #E26135 100%);
                border-radius: 5px;
                margin: 19px auto 0;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 34px;
                text-align: center;                
            }
            &-modalLease{
                &-title{
                    text-align: center;
                    font-size: 23px;
                    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                    font-weight: 500;
                    color: #232323;
                    padding-top: 18px;
                    margin-bottom: 18px;
                    // line-height: 40.5px;
                  }
                &-icon{
                    text-align: center;
                    margin: 0 auto;
                    padding-top: 20px;
                    &-pic{
                      margin: 0 auto;
                      width: 200px;
                      height: 200px;
                      background-image: url('../../../官网切图/首页/消费者业务二维码.png');
                      background-size: 100% 100%;
                    }
                    &-title{
                      margin-top: 15px;
                      margin-bottom: 20px;
                      font-size: 21px;
                      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                      //font-weight: 500;
                      color: #000000;
                      line-height: 31.5px;
                    }
                }
            }
        }
        &-news {
            :nth-child(2)&-info{
                border-top: 1px solid rgba(0, 0, 0, 0.08);
            }
            &-title {
                height: 28px;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #232323;
                line-height: 28px;
                text-align: center;
                margin: 47px 0 19px;                 
            }
            &-info {
                width: 338px;
                height: 115px;
                background: #FFFFFF;
                margin: 0 auto;
                border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                overflow: hidden;
                &-content {
                    margin-top: 19px;
                    width: 338px;
                    height: 49px;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #282828;
                    line-height: 25px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
                &-bottom {
                    width: 338px;
                    margin-top: 19px;
                    display: flex;
                    justify-content: space-between;
                    &-time {  
                        height: 19px;
                        font-size: 13px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #585858;
                        line-height: 19px;
                    }
                    &-source {
                        height: 19px;
                        font-size: 13px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #585858;
                        line-height: 19px;
                        text-align: right;                                                
                    }
                }
            }
        }
        &-contact {
            overflow: hidden;
            .adm-list{
                &-item {
                    margin-top: 0!important;
                    padding: 0;
                    &-content {
                        border-style: none;
                        padding: 0;
                        &-main {
                            padding: 0;
                            position: relative;
                        }
                    }
                    &-description {
                        margin-left: 19px;
                        position: absolute;
                        z-index: 1;
                        div {
                            margin-top: 0;
                        }
                    }
                }
                &-body {
                    border-style: none;
                }
            }
            &-title {
                height: 28px;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #232323;
                line-height: 28px;
                margin-top: 47px;
                text-align: center;                
            }
            &-input {
                width: 338px;
                margin: 19px auto 0;
                &-style {
                    // background: red;
                    // .input {
                        width: 338px;
                        height: 43px;
                        padding-left: 19px;
                        border-radius: 7px;
                        border: 1px solid #DDDDDD;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ABABAB;
                        line-height: 25px;                        
                    // }
                }
            }
            &-button {
                margin: 19px 127px 0;
                width: 108px;
                height: 34px;
                background: #EB3A32;
                border-radius: 5px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 34px;
                text-align: center;
                border-style: none;
                padding: 0;
                overflow: hidden;
            }
            &-step {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #EB3A32;
                line-height: 25px;
                margin: 38px 19px 0;                
            }
            &-tag {
                position: relative;
                width: 263px;
                height: 30px;
                background: #EB3A32;
                border-radius: 5px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 30px;
                margin: 9px 19px 0;
                text-align: center;
                z-index: 1;
            }
            &-pic {
                width: 273px;
                height: 178px;
                margin: -16px 48px 0;
                //background: pink;
                background-image: url('../../../官网切图/首页/客服人物_画板 2.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            &-phone {
                margin: 23px 20px 47px;
                display: flex;
                &-icon {
                    width: 19px;
                    height: 23px;
                    background-image: url(../../../官网切图/首页/联系电话.png);
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
                &-phoneNumber {
                    margin-left: 10px;
                    height: 21px;
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 21px;
                }   
            }
        }
    }
}

@primary-color: #0099ff;@font-size-base: 14px;