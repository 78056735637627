.pc{
  // margin-top: 15px;
  .pc-first{
    margin: 0 auto;
    width: 1125px;
    height: 530px;
    .f-content{
      margin-top: 120px;
      float: left;
      .f-title{
        margin-top: 153px;
        font-size: 27px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
        line-height: 40.5px;
      }
      .f-tip{
        margin-top: 18px;
        font-size: 19.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393939;
        line-height: 29.25px;
      }
      .f-button{
        &:hover{
          background: url('../../../官网切图/首页/试用按钮.png');
          background-size: 100% 100%;
          color: #ffffff;
          overflow: hidden;
        }
        margin-top: 30px;
        width: 120px;
        height: 42px;
        border-radius: 8px;
        border: 2px solid #EB3A32;
        font-size: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #EB3A32;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
      }
    }
    .f-icon{
      float: left;
      margin-top: 120px;
      margin-left: 208px;
      width: 537px;
      height: 413px;
      background-image: url('../../../官网切图/PC独立站/编组2.png');
      background-size: 100% 100%;
      // border: 1px dashed #000000;
    }
  }
  .pc-second{
    margin: 0 auto;
    width: 1125px;
    // width: 1300px;
    height: 600px;
    .s-icon{
      float: left;
      margin-top: 90px;
      width: 480px;
      height: 509px;
      background-image: url('../../../官网切图/PC独立站/编组.png');
      background-size: 100% 100%;
      // border: 1px dashed #000000;
    }
    .s-content{
      margin-top: 90px;
      margin-left: 31px;
      float: left;
      text-align: right;
      .s-title{
        margin-top: 153px;
        font-size: 27px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
        line-height: 40.5px;
      }
      .s-tip{
        margin-top: 18px;
        font-size: 19.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393939;
        line-height: 29.25px;
      }
      .s-button{
        &:hover{
          background: url('../../../官网切图/首页/试用按钮.png');
          background-size: 100% 100%;
          color: #ffffff;
          overflow: hidden;
        }
        float: right;
        margin-top: 30px;
        width: 120px;
        height: 42px;
        border-radius: 8px;
        border: 2px solid #EB3A32;
        font-size: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #EB3A32;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .pc-third{
    margin: 0 auto;
    width: 1125px;
    // width: 1300px;
    height: 300px;
    margin-bottom: 90px;
    .icon-title{
      margin-top: 90px;
      margin-bottom: 47px;
      text-align: center;
      font-size: 27px;
      font-family: AppleSystemUIFont;
      font-weight: 500;
      color: #232323;
      line-height: 40.5px;
    }
    .t-icons{
      margin: 0 auto;
      width: 1125px;
      .t-icon1{
        float: left;
        margin-left: 63.75px;
        width: 120px;
        height: 120px;
        background-image: url('../../../官网切图/PC独立站/入驻.png');
        background-size: 100% 100%;
        // border: 1px dashed #000000;
      }
      .t-icon2{
        float: left;
        margin-left: 63.75px;
        width: 120px;
        height: 120px;
        background-image: url('../../../官网切图/PC独立站/2.png');
        background-size: 100% 100%;
        // border: 1px dashed #000000;
      }
      .t-icon3{
        float: left;
        margin-left: 63.75px;
        width: 120px;
        height: 120px;
        background-image: url('../../../官网切图/PC独立站/交易管理.png');
        background-size: 100% 100%;
        // border: 1px dashed #000000;
      }
      .t-icon4{
        float: left;
        margin-left: 63.75px;
        width: 120px;
        height: 120px;
        background-image: url('../../../官网切图/PC独立站/风控管理.png');
        background-size: 100% 100%;
        // border: 1px dashed #000000;
      }
      .t-icon5{
        float: left;
        margin-left: 63.75px;
        width: 120px;
        height: 120px;
        background-image: url('../../../官网切图/PC独立站/租后管理.png');
        background-size: 100% 100%;
        // border: 1px dashed #000000;
      }
      .t-icon6{
        float: left;
        margin-left: 63.75px;
        width: 120px;
        height: 120px;
        background-image: url('../../../官网切图/PC独立站/财务结算.png');
        background-size: 100% 100%;
        // border: 1px dashed #000000;
      }
    }
    .t-tips{
      margin: 0 auto;
      width: 1050px;
      margin-top: 23.25px;
      text-align: center;
      .tips3,
      .tips4,
      .tips5,
      .tips6{
        float: left;
        margin-left: 108.75px;
        width: 75px;
        margin-top: 10px;
      }
      .tips1{
        float: left;
        margin-left: 36px;
        margin-top: 10px;
      }
      .tips2{
        float: left;
        margin-left: 108.75px;
        width: 75px;
        margin-top: 10px;
      }
    }
  }
  .pc-service{
    height: 300px;
    background: #EB3A32;
    background-image: url('../../../官网切图/小程序落地页/底部装饰.png');
    background-size: 100% 100%;
    padding-top: 60px;
    margin-bottom: -150px;
    .service-title{
      margin-bottom: 50px;
      font-size: 37.5px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 56.25px;
      text-align: center;
    }
    .service-button{
      margin: 0 auto;
      width: 210px;
      height: 66px;
      background: #FFFFFF;
      border-radius: 8px;
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #EB3A32;
      line-height: 66px;
      text-align: center;
      cursor: pointer;
      &:hover{
        font-size: 38px;
      }
    }
  }
  .modalInquiry{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      width: 600px;
      height: 498px;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .title{
        text-align: center;
        font-size: 27px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 54px;
      }
      .pic-box{
        margin-top: 8px;
        margin-left: 126px;
        width: 300px;
        height: 330px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 2px solid #FFFFFF;
        .pic{
          margin-top: 27px;
          margin-left: 27px;
          width: 246px;
          height: 246px;
          background-image: url('../../../官网切图/二维码/微信客服.png');
          background-size: 100% 100%;
        }
        .tip{
          text-align: center;
          font-size: 21px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #3B6FEF;
          line-height: 42px;
        }
      }
      .content{
        margin-top: 18px;
        text-align: center;
        font-size: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 28px;
      }
    }
  }
}
@primary-color: #0099ff;@font-size-base: 14px;