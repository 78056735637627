.appContent{
  margin-top: 90px;
  .appContent-function{
    margin: 0 auto;
    text-align: center;
    .fun-title{
      margin-top: 90px;
      font-size: 27px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #232323;
      line-height: 38px;
    }
    .fun-tip{
      margin-top: 8px;
      font-size: 20px;
      font-family: AppleSystemUIFont;
      font-weight: 400;
      color: #393939;
      line-height: 23px;
    }
    .fun-icons{
      margin-top: 86px;
      .icons-first{
        margin: 0 auto;
        height: 90px;
        width: 990px;
        .icon-1{
          float: left;
          width: 90px;
          height: 90px;
          background-image: url('../../../官网切图/小程序落地页/功能/店铺入驻.png');
          background-size: 100% 100%;
          .icon-tip{
            margin-top: 102px;
            font-size: 15px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #393939;
            line-height: 21px;
          }
        }
        .icon-2{
          float: left;
          margin-left: 135px;
          width: 90px;
          height: 90px;
          background-image: url('../../../官网切图/小程序落地页/功能/商品.png');
          background-size: 100% 100%;
          .icon-tip{
            margin-top: 102px;
            font-size: 15px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #393939;
            line-height: 21px;
          }
        }
        .icon-3{
          float: left;
          margin-left: 135px;
          width: 90px;
          height: 90px;
          background-image: url('../../../官网切图/小程序落地页/功能/订单.png');
          background-size: 100% 100%;
          .icon-tip{
            margin-top: 102px;
            font-size: 15px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #393939;
            line-height: 21px;
          }
        }
        .icon-4{
          float: left;
          margin-left: 135px;
          width: 90px;
          height: 90px;
          background-image: url('../../../官网切图/小程序落地页/功能/资金.png');
          background-size: 100% 100%;
          .icon-tip{
            margin-top: 102px;
            font-size: 15px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #393939;
            line-height: 21px;
          }
        }
        .icon-5{
          float: left;
          margin-left: 135px;
          width: 90px;
          height: 90px;
          background-image: url('../../../官网切图/小程序落地页/功能/渠道.png');
          background-size: 100% 100%;
          .icon-tip{
            margin-top: 102px;
            font-size: 15px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #393939;
            line-height: 21px;
          }
        }
      }
      .icons-second{
        margin: 0 auto;
        height: 90px;
        width: 990px;
        margin-top: 89px;
        .icon-6{
          float: left;
          width: 90px;
          height: 90px;
          background-image: url('../../../官网切图/小程序落地页/功能/免押金.png');
          background-size: 100% 100%;
          .icon-tip{
            margin-top: 102px;
            font-size: 15px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #393939;
            line-height: 21px;
          }
        }
        .icon-7{
          float: left;
          margin-left: 135px;
          width: 90px;
          height: 90px;
          background-image: url('../../../官网切图/小程序落地页/功能/区块链.png');
          background-size: 100% 100%;
          .icon-tip{
            margin-top: 102px;
            font-size: 15px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #393939;
            line-height: 21px;
          }
        }
        .icon-8{
          float: left;
          margin-left: 135px;
          width: 90px;
          height: 90px;
          background-image: url('../../../官网切图/小程序落地页/功能/司法.png');
          background-size: 100% 100%;
          .icon-tip{
            margin-top: 102px;
            font-size: 15px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #393939;
            line-height: 21px;
          }
        }
        .icon-9{
          float: left;
          margin-left: 135px;
          width: 90px;
          height: 90px;
          background-image: url('../../../官网切图/小程序落地页/功能/租金.png');
          background-size: 100% 100%;
          .icon-tip{
            margin-top: 102px;
            font-size: 15px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #393939;
            line-height: 21px;
          }
        }
        .icon-10{
          float: left;
          margin-left: 135px;
          width: 90px;
          height: 90px;
          background-image: url('../../../官网切图/小程序落地页/功能/在线客服.png');
          background-size: 100% 100%;
          .icon-tip{
            margin-top: 102px;
            font-size: 15px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #393939;
            line-height: 21px;
          }
        }
      }
    }
  }
  .appContent-applet{
    margin: 0 auto;
    text-align: center;
    .applet-title{
      margin-top: 132px;
      font-size: 27px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #232323;
      line-height: 38px;
    }
    .applet-tip{
      margin-top: 12px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393939;
      line-height: 28px;
    }
    .applet-content{
      margin: 0 auto;
      width: 1050px;
      height: 528px;
      .applet-contents{
        float: left;
        margin-top: 78px;
        text-align: left;
        .cons-title{
          margin-top: 18px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #232323;
          line-height: 34px;
        }
        .cons-tip{
          margin-top: 6px;
          font-size: 21px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #585858;
          line-height: 30px;
        }
        .cons-button{
          &:hover{
            background: url('../../../官网切图/首页/试用按钮.png');
            background-size: 100% 100%;
            color: #ffffff;
            overflow: hidden;
          }
          margin-top: 18px;
          width: 120px;
          height: 42px;
          border-radius: 6px;
          border: 2px solid #EB3A32;
          font-size: 21px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #EB3A32;
          line-height: 38px;
          text-align: center;
          cursor: pointer;
        }
      }
      .iconBox{
        float: right;
        // margin-left: 38px;
        margin-top: 48px;
        .con-icon{
          float: left;
          width: 544px;
          height: 480px;
          background-image: url('../../../官网切图/小程序落地页/编组.png');
          background-size: 100% 100%;
          // border: 1px dashed #000000;
        }
      }
    }
  }
  .appContent-programme{
    margin: 0 auto;
    text-align: center;
    .programme-title{
      margin-top: 91px;
      font-size: 27px;
      font-family: AppleSystemUIFont;
      font-weight: 500;
      color: #232323;
      line-height: 32px;
    }
    .programme-tip{
      margin-top: 12px;
      font-size: 20px;
      font-family: AppleSystemUIFont;
      font-weight: 400;
      color: #393939;
      line-height: 23px;
    }
    .programme-icon{
      margin: 0 auto;
      margin-top: 48px;
      width: 1050px;
      height: 300px;
      .icon-left{
        float: left;
        width: 288px;
        height: 300px;
        background-image: url('../../../官网切图/小程序落地页/自营模式.png');
        background-size: 100% 100%;
        position: relative;
        &:hover{
          background-size: 110% 110%;
          background-position: 50% 50%;
          .left-before{
            display: none;
          }
          .left-hover{
            display: block;
          }
        }
        .left-before{
          .title{
            position: absolute;
            left: 18px;
            bottom: 18px;
            font-size: 24px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 36px;
          }
        }
        .left-hover{
          display: none;
          .title{
            position: absolute;
            left: 18px;
            bottom: 80px;
            font-size: 24px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 36px;
          }
          .tip{
            text-align: left;
            position: absolute;
            left: 18px;
            bottom: 21px;
            font-size: 21px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 27px;
          }
        }

      }
      .icon-center{
        float: left;
        margin-left: 93px;
        width: 288px;
        height: 300px;
        background-image: url('../../../官网切图/小程序落地页/平台模式.png');
        background-size: 100% 100%;
        position: relative;
        &:hover{
          background-size: 110% 110%;
          background-position: 50% 50%;
          .center-before{
            display: none;
          }
          .center-hover{
            display: block;
          }
        }
        .center-before{
          .title{
            position: absolute;
            left: 18px;
            bottom: 18px;
            font-size: 24px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 36px;
          }
        }
        .center-hover{
          display: none;
          .title{
            position: absolute;
            left: 18px;
            bottom: 110px;
            font-size: 24px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 36px;
          }
          .tip{
            text-align: left;
            position: absolute;
            left: 18px;
            bottom: 21px;
            font-size: 21px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 27px;
          }
        }
      }
      .icon-right{
        float: left;
        margin-left: 93px;
        width: 288px;
        height: 300px;
        background-image: url('../../../官网切图/小程序落地页/分销模式.png');
        background-size: 100% 100%;
        background-position: 50% 50%;
        position: relative;
        &:hover{
          background-size: 110% 110%;
          .right-before{
            display: none;
          }
          .right-hover{
            display: block;
          }
        }
        .right-before{
          .title{
            position: absolute;
            left: 18px;
            bottom: 18px;
            font-size: 24px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 36px;
          }
        }
        .right-hover{
          display: none;
          .title{
            position: absolute;
            left: 18px;
            bottom: 135px;
            font-size: 24px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 36px;
          }
          .tip{
            text-align: left;
            position: absolute;
            left: 18px;
            bottom: 21px;
            font-size: 21px;
            font-family: AppleSystemUIFont;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 27px;
          }
        }
      }
    }
  }
  .steps{
    margin: 0 auto;
    width: 900px;
    .fourSteps{
      margin-top: 91px;
      width: 900px;
      height: 245px;
      background-image: url('../../../官网切图/小程序落地页/四步.png');
      background-size: 100% 100%;
    }
  }
  .otherStore{
    margin: 0 auto;
    width: 1065px;
    text-align: center;
    .otherStore-title{
      margin-top: 90px;
      font-size: 27px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #232323;
      line-height: 38px;
    }
    .otherStore-tip{
      margin-top: 12px;
      font-size: 14px;
      font-family: AppleSystemUIFont;
      font-weight: 400;
      color: #232323;
      line-height: 16px;
    }
    .icon-box{
      position: relative;
      margin-top: 47px;
      width: 1050px;
      height: 200px;
      display: inline-block;
      overflow: hidden;
      .zhezhaoceng{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 1065px;
        height: 200px;
        display: inline-block;
        overflow: hidden;
        z-index: 100;
        opacity: 1;
      }
      .store-icons{
        white-space: nowrap;
        overflow-x: scroll;
        font-size: 0;
        height:200px;
        padding-top: 2px;
        .store-icon{
          
          animation: infiniteScrool 15s linear infinite;
          display: inline-block;
          @keyframes infiniteScrool {
            0%{
              transform: translate3d(0,0,0);
            }
            100%{
              transform: translate3d(-100%,0,0);
            }
          }
          .icon{
            float: left;
            width: 159px;
            height: 196.5px;
            margin-right: 50px;
            background: #ffffff;
            position: relative;
            box-shadow: 0px 0px 9px 0px rgba(43,2,1,0.1);
            border-radius: 4px;
            overflow: hidden;
            .icon1-bk{
              width: 159px;
              height: 76.5px;
              background-image: url('https://oss.huizustore.com/d640018a3a664d86ab80346019bcfc5a.png');
              background-size: 100% auto;
              overflow: hidden;
            }
            .icon1-head{
              position: absolute;
              top: 49px;
              left: 9px;
              width: 56px;
              height: 56px;
              border-radius: 50%;
              background-image: url('https://oss.huizustore.com/d640018a3a664d86ab80346019bcfc5a.png');
              background-size: 100% auto;
            }
            .icon1-title{
              position: absolute;
              top: 80px;
              left: 68px;
              font-size: 16.5px;
              font-family: AppleSystemUIFont;
              font-weight: 400;
              color: #585858;
              line-height: 33px;
            }
            .icon1-content{
              position: absolute;
              top: 117px;
              left: 12px;
              font-size: 13.5px;
              font-family: AppleSystemUIFont;
              font-weight: 400;
              color: #393939;
              line-height: 19px;
              letter-spacing: 2px;
              text-align: left;
            }
            .icon2-bk{
              width: 159px;
              height: 76.5px;
              background-image: url('https://oss.huizustore.com/82038a891de743d2adc59c1947835b53.png');
              background-size: 100% auto;
              overflow: hidden;
            }
            .icon2-head{
              position: absolute;
              top: 49px;
              left: 9px;
              width: 56px;
              height: 56px;
              border-radius: 50%;
              background-image: url('https://oss.huizustore.com/ead46aeed8774ba2b12e02708194940d.png');
              background-size: 100% auto;
            }
            .icon3-bk{
              width: 159px;
              height: 76.5px;
              background-image: url('https://oss.huizustore.com/77dcacc920754e25b80295179f301b66.jpeg');
              background-size: 100% auto;
              overflow: hidden;
            }
            .icon3-head{
              position: absolute;
              top: 49px;
              left: 9px;
              width: 56px;
              height: 56px;
              border-radius: 50%;
              background-image: url('https://oss.huizustore.com/65a6a2b017e74c46a63ea1df7f232998.jpeg');
              background-size: 100% auto;
            }
            .icon4-bk{
              width: 159px;
              height: 76.5px;
              background-image: url('https://oss.huizustore.com/e10027f933e14650a5afb0cb87691383.png');
              background-size: 100% auto;
              overflow: hidden;
            }
            .icon4-head{
              position: absolute;
              top: 49px;
              left: 9px;
              width: 56px;
              height: 56px;
              border-radius: 50%;
              background-image: url('https://oss.huizustore.com/7f6ff13537dc45b5a3c71642096b68db.png');
              background-size: 100% auto;
            }
            .icon5-bk{
              width: 159px;
              height: 76.5px;
              background-image: url('https://oss.huizustore.com/6bf80e74bbba4010af76856ca4a690cd.png');
              background-size: 100% auto;
              overflow: hidden;
            }
            .icon5-head{
              position: absolute;
              top: 49px;
              left: 9px;
              width: 56px;
              height: 56px;
              border-radius: 50%;
              background-image: url('https://oss.huizustore.com/3981dd6ae1344ac2a9452f546f0844da.png');
              background-size: 100% auto;
            }
          }
        }
      }
      .store-icons::-webkit-scrollbar {
        /*滚动条整体样式*/
        width : 0px!important;  /*高宽分别对应横竖滚动条的尺寸*/
        height: 0px!important;
        }
    }


  }
  .appContent-service{
    margin-top: 150px;
    height: 300px;
    background: #3B6FEF;
    background-image: url('../../../官网切图/小程序落地页/底部装饰.png');
    background-size: 100% 100%;
    padding-top: 60px;
    margin-bottom: -150px;
    .appContent-title{
      margin-bottom: 50px;
      font-size: 37.5px;
      font-family: AppleSystemUIFont;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 56.25px;
      text-align: center;
    }
    .appContent-button{
      margin: 0 auto;
      width: 210px;
      height: 66px;
      background: #FFFFFF;
      border-radius: 8px;
      font-size: 36px;
      font-family: AppleSystemUIFont;
      font-weight: 500;
      color: #EB3A32;
      line-height: 66px;
      text-align: center;
      cursor: pointer;
      &:hover{
        font-size: 38px;
      }
    }
  }
  .modalInquiry{
    backdrop-filter: blur(10px);
    .ant-modal-content{
      width: 600px;
      height: 498px;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 0px 4px 4px rgba(59, 111, 239, 0.1);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      .title{
        text-align: center;
        font-size: 27px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 54px;
      }
      .pic-box{
        margin-top: 8px;
        margin-left: 126px;
        width: 300px;
        height: 330px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 2px solid #FFFFFF;
        .pic{
          margin-top: 27px;
          margin-left: 27px;
          width: 246px;
          height: 246px;
          background-image: url('../../../官网切图/二维码/微信客服.png');
          background-size: 100% 100%;
        }
        .tip{
          text-align: center;
          font-size: 21px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #3B6FEF;
          line-height: 42px;
        }
      }
      .content{
        margin-top: 18px;
        text-align: center;
        font-size: 21px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 28px;
      }
    }
  }
}
@primary-color: #0099ff;@font-size-base: 14px;