.store {
    &-container {
       // width: 375px;
       width: 100%;
        &-header {
            //width: 375px;
            width: 100%;
            height: 261px;
            padding-left: 19px;
            position: relative;
            background-color: #f5f5f5;
            overflow: hidden;
            &-title {
                margin-top: 28px;
                font-size: 35px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #282828;
                line-height: 49px;
            }
            &-top {
                margin-top: 9px;
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #393939;
                line-height: 28px;
            }
            &-bottom {
                margin-top: 18px;
                width: 117px;
                height: 42px;
                background: #EB3A32;
                box-shadow: 0px 2px 9px 0px rgba(235,58,50,0.5);
                border-radius: 26px;
                backdrop-filter: blur(9px);
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                line-height: 42px;
                text-align: center;
                color:#E5E5E5;
                // a {
                //     color: #E5E5E5;
                // }
            }
            &-mount {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 205px;
                height: 70px;
                background: url('../../../官网切图/首页/banner山.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }
        &-pic {
            width: 339px;
            height: 300px;
            margin: 27px 12px 25px;
            // background: #EB3A32;
            background: url('../../../官网切图/首页/banner-mobile.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }
    &-content{

        margin:15px;
        //margin-bottom: 40px;
        text-align: left;
        &-process{
            text-align: center;
            &-title{
                text-align:center !important;
                margin-top: 47px auto 20px;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #232323;
                line-height: 30px;
                text-align: left;
            }
            &-line{
                background-color: rgba(239, 152, 59, 1);
                width: 120px;
                height: 1px;
                margin: 4px auto 4px auto;
            }
            &-tip{
                height:50px;
                margin-top: 9px;
                margin-bottom: 15px;
                font-size: 17px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #585858;
                line-height: 30px;
            }
        }
        .store-content-process-img{
            margin:60px auto 0 ;
            height: 780px;
            background-image: url('../../../官网切图/首页/编组 24.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            line-height: 650px;
            text-align: center;
        }
        &-first, &-second{
            margin-top: 40px;
        }
        .store-content-first-button{
            margin-top: 30px;
        }
        &-title{
            margin-top: 47px auto 20px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #232323;
            line-height: 30px;
            text-align: left;
        }
        &-tip{
            //display: block;
            height:50px;
            margin-top: 9px;
            margin-bottom: 15px;
            font-size: 17px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #585858;
            line-height: 30px;
        }
        .ant-btn{
            border:2px solid #EB3A32;
            color:#EB3A32;
            border-radius:6px;
            margin-top: auto;
            margin-bottom: 15px;
        }
        &-button{
            margin-top: 90px;
        }
        &-icon1{
            background: url('../../../官网切图/租赁店铺页面/引流插画.jpg');
            //background-size: 100% 100%;
            bottom: 30px;
            height:250px;
            width:100%;
            background-size: cover;
        }
        &-icon2{
            background: url('../../../官网切图/租赁店铺页面/0成本开店插画.png');
            //background-size: 100% 100%;
            bottom: 30px;
            height:250px;
            width:100%;
            background-size: cover;
        }
    }
    &-step {
        //width: 375px;
        width:100%;
        height:120px;
        text-align: center;
        //height:0px;
        .fourSteps{
            margin: 10px;
            margin-bottom: 30px;
            height: 100px;
            background-image: url('../../../官网切图/小程序落地页/四步.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            //background-size: cover;
        }
    }
    &-title{
        margin-top: 20px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #232323;
        line-height: 40px;
        text-align: center;

    }
    &-tip{
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #585858;
        line-height: 20px;
        text-align: center;

    }
    .icon-box{
        margin-bottom: 20px;
        //position: relative;
        display: flex;
        flex-wrap: nowrap;
        margin-top: 20px;
        width:100%;
       // width: 375px;
        height: 200px;
        display: inline-block;
        overflow: hidden;
        // .zhezhaoceng{
        //   top: 0;
        //   bottom: 0;
        //   left: 0;
        //   right: 0;
        //   width: 375px;
        //   height: 200px;
        //   display: inline-block;
        //   z-index: 100;
        //   opacity: 1;
        // }
        .store-icons{
          white-space: nowrap;
          overflow-x: scroll;
          font-size: 0;
          height:200px;
          padding-top: 2px;
            .store-icon{
            animation: infiniteScrool 15s linear infinite;
            display: inline-block;
            @keyframes infiniteScrool {
              0%{
                transform: translate3d(0,0,0);
              }
              100%{
                transform: translate3d(-100%,0,0);
              }
            }
            .icon{
              float: left;
              width: 159px;
              height: 196.5px;
              margin-right: 20px;
              background: #ffffff;
              position: relative;
              box-shadow: 0px 0px 9px 0px rgba(43,2,1,0.1);
              border-radius: 4px;
              overflow: hidden;
              .icon-bk{
                width: 159px;
                height: 76.5px;
                background-image: url('https://oss.huizustore.com/d640018a3a664d86ab80346019bcfc5a.png');
                background-repeat:no-repeat ;
                background-size: 100% auto;
                //overflow: hidden;
              }
              .icon-head{
                position: absolute;
                top: 49px;
                left: 9px;
                width: 56px;
                height: 56px;
                border-radius: 50%;
                background-image: url('https://oss.huizustore.com/d640018a3a664d86ab80346019bcfc5a.png');
                background-size: 100% auto;
              }
              .icon-title{
                position: absolute;
                top: 80px;
                left: 68px;
                font-size: 16.5px;
                font-family: AppleSystemUIFont;
                font-weight: 400;
                color: #585858;
                line-height: 33px;
              }
              .icon-content{
                overflow:hidden;
                //padding: 10px;
                position: absolute;
                //word-break: break-all !important;
                top: 117px;
                left: 12px;
                font-size: 13.5px;
                font-family: AppleSystemUIFont;
                font-weight: 400;
                color: #393939;
                line-height: 1.6;
                overflow-wrap: break-word;
                letter-spacing: 2px;
                text-align: left;
                white-space: pre-wrap !important;
              }
            }
           }
            :nth-child(2){
            .icon-bk{
                background-image: url('https://oss.huizustore.com/82038a891de743d2adc59c1947835b53.png') !important;
              }
            .icon-head{
                background-image: url('https://oss.huizustore.com/ead46aeed8774ba2b12e02708194940d.png') !important;
            }
            }
            :nth-child(3){
                .icon-bk{
                    background-image: url('https://oss.huizustore.com/77dcacc920754e25b80295179f301b66.jpeg') !important;
                }
                .icon-head{
                    background-image: url('https://oss.huizustore.com/65a6a2b017e74c46a63ea1df7f232998.jpeg') !important;
                }
            }
            :nth-child(4){
                .icon-bk{
                    background-image: url('https://oss.huizustore.com/e10027f933e14650a5afb0cb87691383.png') !important;
                }
                .icon-head{
                    background-image: url('https://oss.huizustore.com/7f6ff13537dc45b5a3c71642096b68db.png') !important;
                }
            }
            :nth-child(5){
                .icon-bk{
                    background-image: url('https://oss.huizustore.com/6bf80e74bbba4010af76856ca4a690cd.png') !important;
                }
                .icon-head{
                    background-image: url('https://oss.huizustore.com/3981dd6ae1344ac2a9452f546f0844da.png') !important;
                }
            }
        }
        .store-icons::-webkit-scrollbar {
          /*滚动条整体样式*/
          width : 0px!important;  /*高宽分别对应横竖滚动条的尺寸*/
          height: 0px!important;
          }
      }
    &-service{
        height:220px;
        //width:375px;
        width:100%;
        //background-image: url('../../../官网切图/关于我门/banner-mobile.png');
        background-repeat:no-repeat;
        background-size: cover;
        overflow: hidden;
        background: #EB3A32;
        overflow: hidden;
        &-title{
            text-align: center;
            //padding-top: 18px;
            letter-spacing: 1.5;
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 55px;
        }
        &-button{
            text-align: center;
            .adm-button{
                color: #EB3A32 ;
                letter-spacing: 1.5px;
            
            }
        }
        // &-title{
        //     margin-top: 30px;
        //     text-align: center;
        //     height: 53px;
        //     font-size: 38px;
        //     font-family: PingFangSC-Medium, PingFang SC;
        //     font-weight: 500;
        //     color: #FFFFFF;
        //     line-height: 53px;
        // }
        .hrs{
            margin: 0;
            //margin-top: 14px;
            height: 1px;
            background: rgba(255, 255, 255, 0.4);
        }
        .store-service-action{
            margin: 0 auto;
            display: flex;
            flex-wrap: nowrap;
            height:165px;
            .code-icon{
            // margin-top:61px ;
            // margin-left: 401px;
            margin:20px;
            width: 35%;
            border: 2px solid #EF983B;
            border-radius: 10px;
            background-image: url('../../../官网切图/二维码/微信客服.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            }
            .service-content{
                margin-top: 23px;
                .content-first{
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    
                }
                .content-second{
                    font-size: 15px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    //line-height: 25px;
                }
                .content-third{
                    margin-top: 20px;
                    width: 108px;
                    height: 42px;
                    background: #EF983B;
                    border-radius: 6px;
                    line-height: 42px;
                    text-align: center;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    cursor: pointer;
                    &:hover{
                    background: #FFB15D;
                    }
                }
            &-icon{
            float: left;
            margin-top: 12px;
            width: 349px;
            height: 231px;
            // border: 1px dashed #f2f2f2;
            background-image: url('../../../官网切图/租赁店铺页面/客服.png');
            background-size: 100% 100%;
            }
        }
        }
    }

    .modalInquiry{
       // border:2px solid white;
       .ant-modal-content{
        border-radius: 12px;
        background-color:rgba(255, 255, 255, 0.7);;
            .title{
                text-align: center;
                font-size: 23px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
            }
            .pic-box{
            // border-radius: 16px;
                text-align: center;
                .pic{
                margin:20px auto;
                width: 230px;
                height: 230px;
                background-image: url('../../../官网切图/首页/客服经理.png');
                background-size: 100% 100%;
                }
                .tip{
                text-align: center;
                font-size: 21px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                line-height: 20px;
                }
            }
        }
        .content{
            margin-top: 10px;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        }
    }
    .modalAppointment{
        .ant-modal-content{
            border-radius: 12px;
            .ant-modal-body{
                .title{
                    text-align: center;
                    font-size: 20px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 500;
                }
                .buttonBox{
                    text-align: center;
                    .button{
                        border-radius: 6px;
                        font-size: 17px;
                        background-color: red;
                        color:#FFFFFF
                    }
                }
            }
        }
    }
}
.adm-list-body{
    background:transparent !important;
}
.adm-list-item-content-prefix{
    width:40%;
}
// .modalAppointment .contact .contact-box-input{
//     width:100% !important;
// }
.adm-form-item-label{
    width:70%;
    white-space:nowrap;
}
// .modalAppointment{
//         font-family: PingFangSC-Regular, PingFang SC;
//         font-weight: 400;
//         background:transparent;
//         .contact{
//             vertical-align: center;
//             border-radius: 6px;
//             &-tip{
//                 float: left;
//                 //width:35%;
//                 font-size: 17px;
//                 font-weight: 500;  
//             }
           
//             .contact-box-input{
//                 //float:right;
//                 width:60%;
//                 border:1.5px solid #fff;
//                 border-radius: 6px;
//                 background-color: rgba(255, 251, 251, 0.5);
//                 padding:2px;
//             }
            
//         }
//         .contact-btn{
//             display: flex;
//             justify-content: center;
//         }
//         .contact-button{
//            // width:90px;
//             text-align: center;
//             letter-spacing: 2px;
//            // line-height: 35px;
//             color:#fff;
//             border:none;
//             background-color: red;
//             margin-bottom: 20px;
//         }
    
@primary-color: #0099ff;@font-size-base: 14px;