.about {
    &-header {
        width: 100%;
        height: 162px;
        background: url('../../../官网切图/关于我门/banner-mobile.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;
        &-title {
            // height: 33px;
            margin-top: 64px;
            font-size: 23px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 33px;
            text-align: center;
        }
    } 
    &-title {
        margin-top: 19px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #232323;
        line-height: 28px;
        text-align: center;
    }
    &-tip {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #585858;
        line-height: 19px;
        text-align: center;
    }
    &-pic {
        margin: 9px auto 0;
        width: 338px;
        height: 103px;
        background: url('../../../官网切图/关于我门/公司LOGO设计图.png') ;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    &-describe {
        &-title {
            display: inline-block;
            height: 25px;
            margin: 19px 0 0 19px ;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #232323;
            line-height: 25px;
            border-bottom: 9px solid rgba(235,58,50,0.5000);
        }
        &-content {
            width: 338px;
            margin: 9px auto 0;
            height: 338px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393939;
            line-height: 19px;            
        }
    }
    &-team {
        width: 338px;
        margin: 28px auto;
        &-title {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #232323;
            line-height: 28px;
            text-align: center;
        }
        &-tip {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #585858;
            line-height: 19px;
            text-align: center;  
        }
        &-content {
            width: 338px;
            height: 123px;
            margin-top: 19px;
            display: flex;
            justify-content: space-between;
            &-left {
                width: 105px;
                height: 123px;
                background: url('../../../官网切图/首页/李.jpg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            &-right {
                width: 221px;
                height: 123px;
                &-name {
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #232323;
                    line-height: 21px;
                }
                &-resume {
                    width: 221px;
                    margin-top: 9px;
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #585858;
                    line-height: 21px;                    
                }
            }
        }
        :nth-child(4) {
            .about-team-content-left {
                background: url('../../../官网切图/首页/杨.jpg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }
        :nth-child(5) {
            .about-team-content-left {
                background: url('../../../官网切图/关于我门/王.jpg');
                background-size: 100%;
                background-position-y: -7px;
                background-repeat: no-repeat;
            }
        }
        :nth-child(6) {
            .about-team-content-left {
                background: url('../../../官网切图/关于我门/周.jpg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }
    }
    &-trial {
        position: relative;
      //  width: 375px;
        width:100%;
        height: 122px;
        margin-top: 47px;
        background: linear-gradient(180deg, #FFF5F5 0%, #FFFFFF 100%);   
        background-image: url('../../../官网切图/关于我门/编组44.png');
        background-size: 257% 210%;
        background-repeat: no-repeat;
        background-position: -175px -8px;  
        overflow: hidden;
        &-title {
            margin: 19px 0 0 19px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #EB3A32;
            line-height: 25px;
        }
        &-button {
            width: 117px;
            height: 34px;
            margin: 19px 0 0 19px;
            background: #EB3A32;
            border-radius: 5px;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 34px;
            text-align: center;
        }
        &-pic-r {
            position: absolute;
            top: 19px;
            right: 0;
            width: 117px;
            height: 88px;
            background-image: url('../../../官网切图/关于我门/编组45.png');
            background-size: 100% 100%;   
            background-repeat: no-repeat;  
        }
    }
    &-slogan {
       // width: 375px;
        width:100%;
        height: 177px;
        overflow: hidden;
        background-image: url('../../../官网切图/关于我门/底部装饰-mobile.png');
        background-size: 100% 100%;   
        background-repeat: no-repeat;  
        &-title {
            margin-top: 19px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 25px;
            text-align: center;
        }
        &-content {
            width: 338px;
            height: 101px;
            margin: 14px auto;
            display: flex;
            justify-content: space-between;
            &-item {
                width: 105px;
                height: 101px;
                background: #FFFFFF;
                border-radius: 5px;
                &-title {
                    margin: 9px auto 0;
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #282828;
                    line-height: 21px;  
                    text-align: center;                  
                }
                &-content {
                    margin: 5px auto 0;
                    width: 87px;
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #585858;
                    line-height: 19px;   
                    text-align: center;                 
                }
            }
        }
    }
    .modalAppointment{
        .ant-modal-content{
            border-radius: 12px;
            .ant-modal-body{
                .title{
                    text-align: center;
                    font-size: 20px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 500;
                }
                .buttonBox{
                    text-align: center;
                    .button{
                        border-radius: 6px;
                        font-size: 17px;
                        background-color: red;
                        color:#FFFFFF
                    }
                }
            }
        }
    }
    
}


@primary-color: #0099ff;@font-size-base: 14px;