.newsCenter {
    width: 100%;
   // width: 375px;
    &-banner {
        //width: 375px;
        width: 100%;
        height: 162px;
        overflow: hidden;
        background: url('../../../官网切图/新闻中心/banner-mobile.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        &-title {
            height: 33px;
            margin-top: 50px;
            font-size: 23px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 33px;   
            text-align: center;         
        }
        &-tip {
            height: 19px;
            // margin-top: -3px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 19px;
            text-align: center;
        }
    }
    &-newsTabs {
       // width: 338px;
       width:100%;
    }
    &-container {
       // width: 338px;
        width:100%;
        margin: 0 auto 32px 10px;
        height: 368px;
        overflow-x: auto;
        &-content {
            width: 350px;
            height: 94px;
            padding-bottom: 15px;
            padding-top: 13px;
            display: flex;
            justify-content: space-between;
            &-img {
                width: 91px;
                height: 66px;
                img {
                    width: 91px;
                    height: 66px;
                }
            }
            &-info {
                width: 237px;
                height: 66px;
                position: relative;
                &-title {
                    height: 42px;
                    font-size: 15px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #282828;
                    line-height: 21px;
                }
                &-date {
                    height: 15px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    font-size: 11px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #585858;
                    line-height: 15px;
                }
                &-source {
                    height: 15px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    font-size: 11px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #585858;
                    line-height: 15px;
                    text-align: right;
                }
            }
    
        }
    }
}
@primary-color: #0099ff;@font-size-base: 14px;